import React, { createContext, useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import socketIO from "socket.io-client"
import { setNotificationsData } from "../../Redux/actionsReducer"
import { setChatsData } from "../../Redux/chatsReducer"

const SocketContext = createContext()

export const useSocket = () => {
    return useContext(SocketContext)
}

const SocketProvider = ({ children, setNotificationsData, setChatsData, chats, user }) => {
    const [socket, setSocket] = useState(null)

    useEffect(() => {
        const socketInstance = socketIO("http://localhost:3002", {
            transports: ["websocket", "polling", "flashsocket"],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000
        })

        setSocket(socketInstance)

        socketInstance.on('notification', (notification) => {
            // console.log('Received notification:', notification);
        });

        return () => {
            if (socketInstance.readyState === 1) {
                socketInstance.off("connect_error")
                socketInstance.off("connect")
                socketInstance.off("message")
                socketInstance.off("reconnect_attempt")
                socketInstance.off("reconnect")
                socketInstance.off("disconnect")
                socketInstance.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        if (user && socket) {
            socket.emit("joinApp", { userId: user._id })
        }
    }, [user, socket])

    useEffect(() => {
        if (socket) {
            socket.on('newMessageNotification', (response) => {
                console.log(response, "RESPONSE")
                if (response) {
                    const newChats = [response, ...chats]
                    setChatsData(newChats)
                }
            });
        }
    }, [chats, socket])

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}

let mapStateToProps = (state) => ({
    chats: state.chats.chats,
    user: state.user.user
})

export default connect(mapStateToProps, {
    setNotificationsData,
    setChatsData
})(SocketProvider)