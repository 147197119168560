import { useTranslation } from "react-i18next"
import classes from "./BackButton.module.css"
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const onClick = () => {
        navigate(-1)
    }

    return (
        <button
            className={classes.main}
            onClick={onClick}
        >
            <MdArrowBackIos/>
            <p>{t("actions.back")}</p>
        </button>
    )
}

export default BackButton