import { useTranslation } from 'react-i18next'
import classes from './Facts.module.css'
import { FaRegEdit } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form"
import Select from '../../../UI/Form/Select/Select';
import { MenuItem } from '@mui/material';
import { BODDIES, LOOKING_FOR, RELATIONSHIP, SMOKER } from '../../../../Utils/constants';
import { connect } from 'react-redux';
import { updateProfile } from '../../../../Redux/userReducer';

const start = 100;
const end = 220;
const heightArray = Array.from({ length: end - start + 1 }, (v, i) => i + start);

const Facts = (props) => {
    const { user, updateProfile, isMe = true } = props

    const { t } = useTranslation()

    const { handleSubmit, reset, control } = useForm()
    
    const static_facts = [
        {
            title: t("myProfile.looking_for"),
            value: user.facts?.looking_for ? t(`myProfile.looking_for_options.${user.facts?.looking_for}`) : null
        },
        {
            title: t("myProfile.relationship"),
            value: user.facts?.relationship ? t(`myProfile.relationship_options.${user.facts?.relationship}`) : null
        },
        {
            title: t("myProfile.height"),
            value: user.facts?.looking_for?.height ? `${user.facts.looking_for.height}cm` : "0cm"
        },
        {
            title: t("myProfile.body"),
            value: user.facts?.body ? t(`myProfile.boddy_options.${user.facts?.body}`) : null
        },
        {
            title: t("myProfile.smoker"),
            value: user.facts?.smoker ? t(`myProfile.smoker_options.${user.facts?.smoker}`) : null
        }
    ]

    const [isEditMode, setIsEditMode] = useState(false)

    const handleEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const onSubmit = (data) => {
        updateProfile(user.username, data).then(response => {
            if (response) {
                handleEditMode()
            }
        })
    }

    useEffect(() => {
        reset({
            facts: {
                looking_for: user.facts?.looking_for,
                relationship: user.facts?.relationship,
                height: user.facts?.looking_for?.height,
                body: user.facts?.looking_for.body,
                smoker: user.facts?.looking_for?.smoker
            }
        })
    }, [])

    return (
        <div className={`${classes.main} ${isMe ? "" : classes.notMe}`}>
            <div className={classes.header}>
                <h4>{t("myProfile.facts")}</h4>
                {isMe && (
                    <button onClick={handleEditMode}>
                        <FaRegEdit/>
                    </button>
                )}
            </div>
            {!isEditMode ? (
                <div className={classes.static}>
                    {static_facts.map(el => (
                        <div className={classes.fact}>
                            <label>{el.title}</label>
                            <p>{el.value || t("myProfile.noAnswer")}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <div className={classes.fields}>
                        <Controller
                            name="facts.looking_for"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.field}>
                                    <label>{t("myProfile.looking_for")}</label>
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                    >
                                        {LOOKING_FOR.map(el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {t(`myProfile.looking_for_options.${el}`)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        />
                        <Controller
                            name="facts.relationship"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.field}>
                                    <label>{t("myProfile.relationship")}</label>
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                    >
                                        {RELATIONSHIP.map(el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {t(`myProfile.relationship_options.${el}`)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        />
                    </div>
                    <div className={classes.fields}>
                        <Controller
                            name="facts.height"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.field}>
                                    <label>{t("myProfile.height")}</label>
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                    >
                                        {heightArray.map(el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {el}cm
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        />
                        <Controller
                            name="facts.body"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.field}>
                                    <label>{t("myProfile.body")}</label>
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                    >
                                        {BODDIES.map(el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {t(`myProfile.boddy_options.${el}`)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        />
                    </div>
                    <div className={classes.fields}>
                        <Controller
                            name="facts.smoker"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.field}>
                                    <label>{t("myProfile.smoker")}</label>
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        error={error}
                                    >
                                        {SMOKER.map(el => (
                                            <MenuItem
                                                key={el}
                                                value={el}
                                            >
                                                {t(`myProfile.smoker_options.${el}`)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        />
                        <div style={{ width: "100%" }} />
                    </div>
                    <div className={classes.buttons}>
                        <button
                            className={classes.cancel}
                            onClick={handleEditMode}
                            type="button"
                        >
                            {t("myProfile.abort")}
                        </button>
                        <button className={classes.submit} type="submit">{t("myProfile.save")}</button>
                    </div>
                </form>
            )}
        </div>
    )
}

export default connect(null, {
    updateProfile
})(Facts)