import { useTranslation } from 'react-i18next'
import AuthClientLayout from "../../../Components/Auth/Client/AuthClientLayout/AuthClientLayout"
import AuthModal from "../../../Components/Auth/Client/AuthModal/AuthModal"
import Header from "../../../Components/UI/Text/Header/Header"
import { Controller, useForm } from "react-hook-form"
import Input from '../../../Components/UI/Form/Input'
import classes from "./Auth.module.css"
import CustomButton from "../../../Components/UI/CustomButton/CustomButton"
import Select from '../../../Components/UI/Form/Select/Select'
import { MenuItem, RadioGroup } from '@mui/material'
import RadioButton from '../../../Components/UI/Form/RadioButton/RadioButton'
import Text from '../../../Components/UI/Text/Text/Text'
import CustomCheckbox from '../../../Components/UI/Form/Checkbox/CustomCheckbox'
import Link from '../../../Components/UI/Text/Link/Link'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { login, register } from '../../../Redux/userReducer'
import { setServerError } from '../../../Redux/commonReducer'
import { useNavigate } from 'react-router'
import { useState } from "react"

const start = 18;
const end = 99;
const ageArray = Array.from({ length: end - start + 1 }, (v, i) => i + start);

const SignUp = (props) => {
    const { register, login, serverError, setServerError, isAuth, user } = props

    const [location, setLocation] = useState("")

    const navigate = useNavigate()

    const { t } = useTranslation()

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        setServerError(null)
        data.location = location
        register(data).then(response => {
            if (response) {
                login({
                    username: data.username,
                    password: data.password
                })
            }
        })
    }

    useEffect(() => {
        fetch('http://ip-api.com/json/')
        .then(response => response.json())
        .then(data => setLocation(data.regionName))
        .catch(error => console.error('Error:', error));

        reset({
            gender: "man",
            filter_settings: {
                looking_for: "woman"
            },
            age: 18
        })
    }, [])
    

    useEffect(() => {
        return () => setServerError(null)
    }, [])

    useEffect(() => {
        if (isAuth && user) {
            const previousPath = localStorage.getItem('previousPath');
            localStorage.removeItem('previousPath');
            navigate(previousPath);
        }
    }, [isAuth, user])

    return (
        <AuthClientLayout>
            <AuthModal>
                <div className={classes.content}>
                    <Header type="h3" text={t("auth.signup.title")}/>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <Controller
                            name="username"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("errors.required") }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    placeholder={t("auth.username")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t("errors.required"),
                                pattern: {
                                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/,
                                    message: t("errors.email")
                                }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    placeholder={t("auth.email")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    type="email"
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t("errors.required"),
                                pattern: {
                                    value: /^[a-zA-Z0-9]{4,}$/,
                                    message: t("errors.invalidPassword")
                                }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    placeholder={t("auth.password")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    type="password"
                                />
                            )}
                        />
                        <Controller
                            name="age"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Select
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    label={t("auth.signup.age")}
                                >
                                    {ageArray.map(el => (
                                        <MenuItem
                                            key={el}
                                            value={el}
                                        >
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.radio}>
                                    <Text type="label" text={t("auth.signup.genderLabel")}/>
                                    <RadioGroup
                                        value={value}
                                        onChange={onChange}
                                        sx={{
                                            "&.MuiFormGroup-root": {
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }
                                        }}
                                    >
                                        <RadioButton value="woman" label={t("auth.signup.woman")} />
                                        <RadioButton value="man" label={t("auth.signup.man")} />
                                    </RadioGroup>
                                </div>
                            )}
                        />
                        <Controller
                            name="filter_settings.looking_for"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className={classes.radio}>
                                    <Text type="label" text={t("auth.signup.looking_for")}/>
                                    <RadioGroup
                                        value={value}
                                        onChange={onChange}
                                        sx={{
                                            "&.MuiFormGroup-root": {
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }
                                        }}
                                    >
                                        <RadioButton value="woman" label={t("auth.signup.woman")} />
                                        <RadioButton value="man" label={t("auth.signup.man")} />
                                    </RadioGroup>
                                </div>
                            )}
                        />
                        <CustomCheckbox
                            required={true}
                            label={
                                <div className={classes.rules}>
                                    <p>I have read and accepted the <Link to="/" text="Terms and Conditions & Communication Guidelines"/> and the <Link to="/" text="Privacy Policy"/>.</p>
                                </div>
                            }
                        />
                        {serverError && <Text type="error" text={t(`errors.${serverError}`)}/>}
                        <CustomButton type="submit" text={t("auth.signup.submit")}/>
                    </form>
                </div>
            </AuthModal>
        </AuthClientLayout>
    )
}

let mapStateToProps = (state) => ({
    serverError: state.common.serverError,
    isAuth: state.user.isAuth,
    user: state.user.user
})

export default connect(mapStateToProps, {
    register,
    login,
    setServerError
})(SignUp)