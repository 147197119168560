import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classes from "./AboutMeForm.module.css"
import { connect } from 'react-redux';
import { Controller, useForm } from "react-hook-form"
import { FaRegEdit } from "react-icons/fa";
import Textarea from "../../UI/Form/Textarea";
import { updateProfile } from "../../../Redux/userReducer";

const AboutMeForm = (props) => {
    const { user, updateProfile } = props

    const { t } = useTranslation()

    const { handleSubmit, reset, control } = useForm()

    const [isEditMode, setIsEditMode] = useState(false)

    const handleEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const onSubmit = (data) => {
        updateProfile(user.username, data).then(response => {
            if (response) {
                handleEditMode()
            }
        })
    }

    useEffect(() => {
        reset({
            about_me: user.about_me
        })
    }, [user])

    return (
        <div className={classes.main}>
            {!isEditMode && (
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <h4>{t("myProfile.aboutMe")}</h4>
                            {user.about_me ? (
                                <p className={classes.aboutMeText}>{user.about_me}</p>
                            ) : (
                                <div
                                    className={classes.emptyPlaceholder}
                                    onClick={handleEditMode}
                                >
                                    <p>{t("myProfile.aboutMePlaceholder")}</p>
                                </div>
                            )}
                    </div>
                    {!isEditMode && (
                        <button onClick={handleEditMode}>
                            <FaRegEdit/>
                        </button>
                    )}
                </div>
            )}
            {isEditMode && (
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <Controller
                        name="about_me"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className={classes.field}>
                                <label>{t("myProfile.aboutMe")}</label>
                                <Textarea
                                    placeholder={t("myProfile.aboutMePlaceholder")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    multiline={true}
                                    rows={3}
                                />
                            </div>
                        )}
                    />
                    <div className={classes.buttons}>
                        <button
                            className={classes.cancel}
                            onClick={handleEditMode}
                            type="button"
                        >
                            {t("myProfile.abort")}
                        </button>
                        <button className={classes.submit} type="submit">{t("myProfile.save")}</button>
                    </div>
                </form>
            )}
        </div>
    )
}

export default connect(null, {
    updateProfile
})(AboutMeForm)