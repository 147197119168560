import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
    color: 'var(--text-color)',
    backgroundColor: 'var(--primary-color)',
    border: '1px solid var(--primary-color)',
    padding: '.5rem 1rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 400,
    borderRadius: 0,
    textTransform: 'initial',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '&:hover': {
        backgroundColor: 'var(--primary-color-hover)',
    },
    '& svg': {
        fontSize: '20px',
    }
}));

const CustomButton = (props) => {
    const {
        icon,
        text,
        type = "button",
        onClick,
        className,
        disabled = false
    } = props
    
    return (
        <StyledButton
            type={type}
            onClick={onClick}
            className={className}
            disabled={disabled}
        >
            {icon}
            {text}
        </StyledButton>
    )
}

export default CustomButton