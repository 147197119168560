import classes from "./MainNavigation.module.css"
import { IoIosChatboxes } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { BsFillStarFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { AiFillHeart } from "react-icons/ai";
import { IoEye } from "react-icons/io5";
import { useNavigate } from "react-router";
import { MdOutlineSearch } from "react-icons/md";
import { ROUTES } from "../../../../Utils/constants";

const MainNavigation = (props) => {
    const { handleClickSearch } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <>
            <div className={classes.main}>
                <div className={classes.buttons}>
                    <button onClick={() => navigate(ROUTES.client.chats)}>
                        <p>{t("mainNavigation.chats")}</p>
                        <IoIosChatboxes/>
                    </button>
                    <button onClick={() => navigate(ROUTES.client.favorites)}>
                        <p>{t("mainNavigation.favorites")}</p>
                        <BsFillStarFill/>
                    </button>
                </div>
                <div className={classes.buttons}>
                    <button onClick={() => navigate(ROUTES.client.likes)}>
                        <p>{t("mainNavigation.likes")}</p>
                        <AiFillHeart/>
                    </button>
                    <button onClick={() => navigate(ROUTES.client.visitors)}>
                        <p>{t("mainNavigation.visitors")}</p>
                        <IoEye/>
                    </button>
                </div>
            </div>
            <div className={classes.mainMobile}>
                <button className={classes.searchButt} onClick={handleClickSearch}>
                    <MdOutlineSearch/>
                </button>
                <button onClick={() => navigate(ROUTES.client.chats)}>
                    <IoIosChatboxes/>
                </button>
                <button onClick={() => navigate(ROUTES.client.favorites)}>
                    <BsFillStarFill/>
                </button>
                <button onClick={() => navigate(ROUTES.client.likes)}>
                    <AiFillHeart/>
                </button>
                <button onClick={() => navigate(ROUTES.client.visitors)}>
                    <IoEye/>
                </button>
            </div>
        </>
    )
}

export default MainNavigation