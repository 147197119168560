import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES } from './constants';

const Languages = LANGUAGES.map(el => el.code);

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        detection: {
            order: ['queryString', 'cookie', 'navigator'], // Added 'navigator' for browser language detection
            caches: ['cookie'] // Cache detected language in cookies
        },
        interpolation: {
            escapeValue: false
        },
        supportedLngs: Languages // Use 'supportedLngs' instead of 'whitelist'
    });

export default i18n;
