import Overflow from "../../UI/Overflow/Overflow"
import { IoCloseSharp } from "react-icons/io5";
import classes from "./SendMessageModal.module.css"
import Textarea from "../../UI/Form/Textarea";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../UI/CustomButton/CustomButton";
import { LuSendHorizonal } from "react-icons/lu";
import { useSocket } from "../../../Components/Common/SocketProvider"
import { connect } from "react-redux";
import { setIsBuySubscribeOpen, setIsFetching } from "../../../Redux/commonReducer";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../Utils/constants"

const SendMessageModal = (props) => {
    const {
        onClose,
        photo,
        user,
        setIsFetching,
        setIsBuySubscribeOpen,
        chatId
    } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const socket = useSocket()

    const [value, setValue] = useState("")
    
    const handleValue = (event) => {
        setValue(event.target.value)
    }

    const onSubmit = () => {
        if (value) {
            setIsFetching(true)
            socket.emit('newMessage', {
                chatId: chatId, 
                userId: user._id, 
                messageContent: value,
                messageType: 'message'
            });
    
            socket.once("send_message_error", (response) => {
                setIsBuySubscribeOpen(true)
            });
    
            socket.once("messageResponse", (response) => {
                if (response.success) {
                    navigate(`${ROUTES.client.chats}/${chatId}`)
                }
            });
            setValue('');
            setIsFetching(false)
        }
    }

    return (
        <Overflow>
            <div className={classes.main}>
                <div className={classes.image}>
                    <button onClick={onClose}>
                        <IoCloseSharp/>
                    </button>
                    <img src={photo} alt="Photo"/>
                </div>
                <div className={classes.chat}>
                    <div className={classes.input}>
                        <Textarea
                            onChange={handleValue}
                            value={value}
                            multiline
                            rows={2}
                            placeholder={t("chat.placeholder")}
                        />
                    </div>
                    <CustomButton
                        icon={<LuSendHorizonal/>}
                        type="button"
                        onClick={onSubmit}
                    />
                </div>
            </div>
        </Overflow>
    )
}

export default connect(null, {
    setIsFetching,
    setIsBuySubscribeOpen
})(SendMessageModal)