import classes from "./ChatModal.module.css"
import { IoResizeSharp } from "react-icons/io5";
import { BiExitFullscreen, BiMinus } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { ROUTES } from "../../../../Utils/constants";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import ChatForm from "../../ChatForm/ChatForm";
import Message from "../../Messages/Message/Message";
import { useSocket } from "../../../Common/SocketProvider";

const ChatModal = (props) => {
    const { chat, user, setIsBuySubscribeOpen, setChatData, handleClose } = props

    const companion = chat.participants.find(u => u._id !== user._id)

    const socket = useSocket()

    const navigate = useNavigate()

    const messagesEndRef = useRef(null);

    const [isCollapsed, setIsCollapsed] = useState(false)

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    useEffect(() => {
        if (user && chat !== null) {
            socket.emit("joinRoom", {
                userId: user._id,
                companionId: chat.participants[0]._id
            })

            socket.on('message', (message) => {
                if (message.chat_id === chat._id) {
                    const newMessages = [ ...chat.messages ]
                    newMessages.push(message)
        
                    setChatData({ ...chat, messages: newMessages })
                }
            });
        }
    }, [user, chat])

    useEffect(() => {
        if (chat) {
            scrollToBottom();
        }
    }, [chat]);

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <NavLink to={`${ROUTES.client.profile}/${companion.username}`} className={classes.userInfo}>
                    <img src={companion.photos[0]} alt={companion.username}/>
                    <p>{companion.username}</p>
                </NavLink>
                <div className={classes.actions}>
                    <button
                        className={classes.action}
                        onClick={() => navigate(`${ROUTES.client.chats}/${chat._id}`)}
                    >
                        <IoResizeSharp/>
                    </button>
                    <button
                        className={classes.action}
                        onClick={handleCollapse}
                    >
                        {isCollapsed ? <BiExitFullscreen/> : <BiMinus/>} 
                    </button>
                    <button className={classes.action} onClick={() => handleClose(chat._id)}>
                        <IoCloseSharp/>
                    </button>
                </div>
            </div>
            <div
                className={classes.collapse}
                style={{ height: isCollapsed ? 0 : `300px` }}
            >
                <div className={classes.chat}>
                    {chat.messages.map(el => (
                        <Message
                            key={el._id}
                            item={el}
                            companion={companion}
                            user={user}
                            type="small"
                        />
                    ))}
                    <div ref={messagesEndRef}/>
                </div>
                <ChatForm
                    chat={chat}
                    user={user}
                    setIsBuySubscribeOpen={setIsBuySubscribeOpen}
                    setChatData={setChatData}
                    type={"window"}
                />
            </div>
        </div>
    )
}

export default ChatModal