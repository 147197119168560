import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getProfiles } from "../../../Redux/profilesReducer"
import Home from "./Home"

const HomeContainer = (props) => {
    const {
        profiles,
        user,
        getProfiles
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)

    const fetchProfiles = (isInfinity) => {
        getProfiles({
            count: pageNumber,
            limit: pageSize,
            looking_for: user?.filter_settings?.looking_for || "",
            age_min: user?.filter_settings?.age_range?.min || "",
            age_max: user?.filter_settings?.age_range?.max || "",
            preferences: user?.filter_settings?.preferences.join(",") || "",
            randomize: true,
            current_user: user?.username || "",
            isInfinity: isInfinity
        })
    }

    useEffect(() => {
        if (user) {
            fetchProfiles()
        }
    }, [user])

    return (
        <Home
            profiles={profiles}
            getProfiles={fetchProfiles}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
        />
    )
}

let mapStateToProps = (state) => ({
    profiles: state.profiles.profiles,
    user: state.user.user
})

export default connect(mapStateToProps, {
    getProfiles
})(HomeContainer)