import classes from "./CustomCheckbox.module.css"
import Checkbox from '@mui/material/Checkbox';
import { THEME } from "../../../../Utils/constants";

const CustomCheckbox = (props) => {
    const { value, onChange, label, required } = props

    return (
        <div className={classes.main}>
            <Checkbox 
                value={value}
                onChange={onChange}
                required={required}
                sx={{
                    "&.MuiCheckbox-root": {
                        padding: 0
                    },
                    '& .MuiSvgIcon-root': {
                        borderRadius: 0, 
                        color: THEME.primaryColor
                    },
                    '&.Mui-checked': {
                        color: THEME.primaryColor
                    },
                }}
            />
            {label}
        </div>
    )
}

export default CustomCheckbox