import { useState } from "react"
import { useTranslation } from "react-i18next"
import classes from "./PersonalInfo.module.css"
import { FaFolderOpen } from "react-icons/fa";
import { RiArrowDropUpLine } from "react-icons/ri";
import Facts from './Facts/Facts'
import { Collapse } from "@mui/material";
import Preferences from "./Preferences/Preferences";
import PersonalEmail from "./PersonalEmail/PersonalEmail";

const PersonalInfo = (props) => {
    const { user } = props

    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(true)

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={classes.main}>
            <div
                className={`${classes.header} ${isOpen ? classes.open : ""}`}
                onClick={handleOpen}
            >
                <div className={classes.headerTitle}>
                    <FaFolderOpen/>
                    <h4>{t("myProfile.personalInfo")}</h4>
                </div>
                <span>
                    <RiArrowDropUpLine/>
                </span>
            </div>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Facts user={user}/>
                <Preferences user={user}/>
                <PersonalEmail user={user}/>
            </Collapse>
        </div>
    )
}

export default PersonalInfo