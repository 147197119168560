import { NavLink } from "react-router-dom"
import classes from "./Sidebar.module.css"
import { ROUTES } from "../../../../Utils/constants";
import { useTranslation } from "react-i18next";
import Filter from "./Filter/Filter";
import { BsHearts } from "react-icons/bs";

import man_empty from "../../../../Assets/empty-male.jpeg"
import female_empty from "../../../../Assets/empty-female.jpg"
import { connect } from "react-redux";
import { getProfiles } from "../../../../Redux/profilesReducer";
import NotificationItem from "../../../Common/NotificationItem/NotificationItem";
import { readNotification, setNotificationsData } from "../../../../Redux/actionsReducer";
import { setIsBuySubscribeOpen } from "../../../../Redux/commonReducer";

const Sidebar = (props) => {
    const {
        user,
        getProfiles,
        notifications,
        readNotification,
        setNotificationsData,
        setIsBuySubscribeOpen
    } = props

    const { t } = useTranslation()

    const onRemoveNotification = (id) => {
        const newNotifications = notifications.filter(el => el._id !== id)
        readNotification(id)
        setNotificationsData(newNotifications)
    }

    return (
        <div className={classes.main}>
            <div className={classes.userInfo}>
                <NavLink to={ROUTES.client.myProfile} className={classes.userPhoto}>
                    {user.photos.length > 0 ? (
                        <img src={user.photos[0]} alt="user photo"/>
                    ) : (
                        user.gender === "man" ? (
                            <img src={man_empty} alt="user photo"/>
                        ) : <img src={female_empty} alt="user photo"/>
                    )}
                </NavLink>
                <div className={classes.userInfoData}>
                    <NavLink to={ROUTES.client.myProfile}>
                        {user.username} ({user.age})
                    </NavLink>
                    {/* <NavLink to={ROUTES.client.myProfile}>
                        {t("sidebar.from")}&nbsp;{user.location}
                    </NavLink> */}
                </div>
            </div>
            {!user?.is_subscribed && (
                <button
                    className={classes.buySubscription}
                    onClick={() => setIsBuySubscribeOpen(true)}
                >
                    <span>{t("actions.buySubscribe")}</span>
                    <BsHearts/>
                </button>
            )}
            <Filter user={user} getProfiles={getProfiles} setIsBuySubscribeOpen={setIsBuySubscribeOpen}/>
            <div className={classes.notifications}>
                {notifications.map(el => (
                    <NotificationItem
                        key={el._id}
                        item={el}
                        onRemoveNotification={onRemoveNotification}
                    />
                ))}
            </div>
        </div>
    )
}

let mapStateToProps = (state) => ({
    notifications: state.actions.notifications
})

export default connect(mapStateToProps, {
    getProfiles,
    readNotification,
    setNotificationsData,
    setIsBuySubscribeOpen
})(Sidebar)