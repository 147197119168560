import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classes from "./PersonalEmail.module.css"
import { FaRegEdit } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form"
import Input from "../../../UI/Form/Input";
import Text from "../../../UI/Text/Text/Text";
import { connect } from "react-redux";
import { updateProfile } from "../../../../Redux/userReducer";

const PersonalEmail = (props) => {
    const { user, serverError, updateProfile } = props

    const { t } = useTranslation()

    const { handleSubmit, reset, control } = useForm()

    const [isEditMode, setIsEditMode] = useState(false)

    const handleEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const onSubmit = (data) => {
        updateProfile(user.username, data).then(response => {
            if (response) {
                handleEditMode()
            }
        })
    }

    useEffect(() => {
        reset({ email: user.email })
    }, [user])

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <div className={classes.headerText}>
                    <h4>{t("myProfile.personal_information")}</h4>
                    <span>{t("myProfile.secure")}</span>
                </div>
                <button onClick={handleEditMode}>
                    <FaRegEdit/>
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("errors.required") }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className={classes.field}>
                            <label>*{t("myProfile.email")}</label>
                            <Input
                                placeholder={t("auth.email")}
                                onChange={onChange}
                                value={value}
                                error={error}
                            />
                            {serverError && <Text type="error" text={t(`errors.${serverError}`)}/>}
                        </div>
                    )}
                />
                {isEditMode && (
                    <div className={classes.buttons}>
                        <button
                            className={classes.cancel}
                            onClick={handleEditMode}
                            type="button"
                        >
                            {t("myProfile.abort")}
                        </button>
                        <button
                            className={classes.submit}
                            type="submit"
                        >
                            {t("myProfile.save")}
                        </button>
                    </div>
                )}
            </form>
        </div>
    )
}

let mapStateToProps = (state) => ({
    serverError: state.common.serverError
})

export default connect(mapStateToProps, {
    updateProfile
})(PersonalEmail)