import { useTranslation } from "react-i18next"
import { ROUTES } from "../../../Utils/constants"
import classes from "./MyProfile.module.css"
import { IoIosChatboxes } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { AiFillHeart } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import CustomButton from "../../../Components/UI/CustomButton/CustomButton";
import MainUserInfoProgress from "../../../Components/MyProfile/MainUserInfoProgress/MainUserInfoProgress";
import UsernameLocationForm from "../../../Components/MyProfile/UsernameLocationForm/UsernameLocationForm";
import PersonalInfo from "../../../Components/MyProfile/PersonalInfo/PresonalInfo";

const MyProfile = (props) => {
    const { user, likes, visitors, handlePhotoModal, newMessages } = props

    const { t } = useTranslation()

    const hotCounters = [
        {
            title: t("myProfile.messages"),
            pathname: ROUTES.client.inbox,
            total: newMessages,
            icon: <IoIosChatboxes/>,
            actionText: t("myProfile.hot_read")
        },
        {
            title: t("myProfile.visitors"),
            pathname: ROUTES.client.visitors,
            total: visitors.length,
            icon: <IoEye/>,
            actionText: t("myProfile.hot_view")
        },
        {
            title: t("myProfile.likes"),
            pathname: ROUTES.client.likes,
            total: likes.length,
            icon: <AiFillHeart/>,
            actionText: t("myProfile.hot_view")
        },
    ]

    return (
        <div className={classes.main}>
            <div className={classes.hotCounters}>
                {hotCounters.map((el, index) => (
                    <NavLink key={index} to={el.pathname} className={classes.hotBlock}>
                        <div className={classes.hotInfo}>
                            {el.icon}
                            <p className={classes.counter}>{el.total}</p>
                            <p className={classes.counterLabel}>{el.title}</p>
                        </div>
                        <CustomButton text={el.actionText}/>
                    </NavLink>
                ))}
            </div>
            <div className={classes.userInfo}>
                <MainUserInfoProgress user={user} handlePhotoModal={handlePhotoModal}/>
                <UsernameLocationForm user={user}/>
                <PersonalInfo user={user}/>
            </div>
        </div>
    )
}

export default MyProfile