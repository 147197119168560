import { useTranslation } from "react-i18next"
import { LANGUAGES } from "../../../Utils/constants"
import classes from "./Languages.module.css"

const Languages = () => {
    const { t, i18n } = useTranslation()

    const handleLang = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <div className={classes.main}>
            <h4>{t("languages.title")}</h4>
            <div className={classes.langs}>
                {LANGUAGES.map(el => (
                    <button
                        key={el.title}
                        onClick={() => handleLang(el.code)}
                    >
                        {el.title}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default Languages