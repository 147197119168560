import BackButton from "../../../Components/Common/BackButton/BackButton"
import classes from "./Profile.module.css"
import OnlineTag from "../../../Components/Profile/OnlineTag/OnlineTag"
import CustomButton from "../../../Components/UI/CustomButton/CustomButton"
import { useTranslation } from "react-i18next"
import { IoIosChatboxes } from "react-icons/io";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { AiFillHeart } from "react-icons/ai";
import { BsFillStarFill } from "react-icons/bs";
import 'swiper/css';
import 'swiper/css/navigation';
import Facts from "../../../Components/MyProfile/PersonalInfo/Facts/Facts"
import Preferences from "../../../Components/MyProfile/PersonalInfo/Preferences/Preferences"
import { useNavigate } from "react-router"
import { ROUTES } from "../../../Utils/constants"

const Profile = (props) => {
    const {
        currentProfile,
        likeUnlike,
        isLiked,
        favoriteUnfavorite,
        isFavorite,
        setCurrentPhoto,
        chatId,
    } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const redirectToChat = () => {
        navigate(`${ROUTES.client.chats}/${chatId}`)
    }

    const actions = [
        {
            title: isLiked ? (currentProfile.gender === "man" ? t("actions.likedHim") : t("actions.likedHer")) : (currentProfile.gender === "man" ? t("actions.likeHim") : t("actions.likeHer")),
            icon: <AiFillHeart/>,
            onClick: likeUnlike
        },
        {
            title: currentProfile.gender === "man" ? t("actions.sendHimMessage") : t("actions.sendHerMessage"),
            icon: <IoIosChatboxes/>,
            onClick: redirectToChat
        },
        {
            title: isFavorite ? t("actions.removeFavorite") : t("actions.addFavorite"),
            icon: <BsFillStarFill/>,
            onClick: favoriteUnfavorite
        },
    ]

    return (
        <div className={classes.main}>
            <BackButton/>
            <div className={classes.header}>
                <div className={classes.mainInfo}>
                    <div className={classes.name}>
                        <h2>{currentProfile.username} ({currentProfile.age})</h2>
                        <OnlineTag isOnline={currentProfile.is_online} size="big"/>
                    </div>
                    <p>&lt; {currentProfile.distance} km</p>
                </div>
                <CustomButton
                    icon={<IoIosChatboxes/>}
                    text={t("actions.sendHerMessage")}
                    onClick={redirectToChat}
                />
            </div>
            <div className={classes.photoWrapper}>
                <Swiper
                    modules={[Navigation]}
                    loop={true}
                    navigation
                    scrollbar={{ draggable: false }}
                    breakpoints={{
                        1023: {
                            slidesPerView: 4,
                        },
                        768: {
                            slidesPerView: 3, // 4 slides per view for screens larger than 568px
                        },
                        568: {
                            slidesPerView: 2, // 4 slides per view for screens larger than 568px
                        },
                        0: {
                            slidesPerView: 1, // 1 slide per view for screens smaller than 568px
                        },
                    }}
                >
                    {currentProfile.photos.map(el => (
                        <SwiperSlide key={el}>
                            <img
                                src={el}
                                alt="Photo"
                                className={classes.slidePhoto}
                                onClick={() => setCurrentPhoto(el)}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className={classes.actions}>
                {actions.map((el, index) => (
                    <button
                        className={`${classes.action} ${(index === 0 && isLiked) ? classes.liked : ""} ${index === 2 && isFavorite ? classes.favorite : ""}`}
                        onClick={el.onClick}
                    >
                        {el.icon}
                        <p>{el.title}</p>
                    </button>
                ))}
            </div>
            <Preferences user={currentProfile} isMe={false}/>
            <Facts user={currentProfile} isMe={false}/>
        </div>
    )
}

export default Profile