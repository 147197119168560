import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { THEME } from '../../../../Utils/constants';

const RadioButton = (props) => {
    const { label, value, onChange } = props

    return (
        <FormControlLabel
            value={value} 
            onChange={onChange}
            label={label}
            control={
                <Radio 
                    sx={{
                        color: THEME.primaryColor,
                        '&.Mui-checked': {
                            color: THEME.primaryColor
                        },
                    }}
                />
            }
            sx={{
                "& .MuiTypography-root": {
                    color: "white",
                    fontSize: "14px"
                }
            }}
        />
    )
}

export default RadioButton