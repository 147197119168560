import classes from "./Preloader.module.css"
import Overflow from "../../UI/Overflow/Overflow"
import preloader from "../../../Assets/preloader.svg"

const Preloader = (props) => {
    const { zIndex = 10 } = props

    return (
        <Overflow zIndex={zIndex}>
            <img src={preloader} alt="preloader" className={classes.img}/>
        </Overflow>
    )
}

export default Preloader