import Overflow from "../Overflow/Overflow"
import classes from './Modal.module.css'
import { IoCloseSharp } from "react-icons/io5";

const Modal = (props) => {
    const { onClose, title, children } = props

    return (
        <Overflow>
            <div className={classes.main}>
                <div className={classes.header}>
                    <h4>{title}</h4>
                    <button onClick={onClose}>
                        <IoCloseSharp/>
                    </button>
                </div>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </Overflow>
    )
}

export default Modal