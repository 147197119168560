import classes from "./PhotoButton.module.css"

import man_empty from "../../../../Assets/empty-male.jpeg"
import female_empty from "../../../../Assets/empty-female.jpg"
import { IoMdAddCircle } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { getDefaultUserPhoto } from "../../../../Utils/getDefaultUserPhoto";

const PhotoButton = (props) => {
    const { user, size, onClick, image, onRemove } = props

    return (
        <div
            onClick={image ? null : onClick}
            className={`${classes.main} ${classes[size]}`}
            style={{
                backgroundImage: `url(${image ? image : getDefaultUserPhoto(user.gender)})`
            }}
        >
            {!image && <div className={classes.red}/>}
            {!image && <IoMdAddCircle/>}
            {image && (
                <button onClick={() => onRemove(image)}>
                    <IoCloseSharp/>
                </button>
            )}
        </div>
    )
}

export default PhotoButton