import { NavLink } from "react-router-dom"
import classes from "./Link.module.css"

const Link = (props) => {
    const { to, text } = props

    return (
        <NavLink to={to} className={classes.main}>
            {text}
        </NavLink>
    )
}

export default Link