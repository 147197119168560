import Profile from "./Profile"
import { useSocket } from "../../../Components/Common/SocketProvider"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getProfile, setCurrentProfileData } from "../../../Redux/profilesReducer"
import { useParams } from "react-router"
import { addToFavorites, setCurrentFavoriteData } from "../../../Redux/actionsReducer"
import SendMessageModal from "../../../Components/Profile/SendMessageModal/SendMessageModal"

const ProfileContainer = (props) => {
    const {
        user,
        getProfile,
        currentProfile,
        setCurrentProfileData,
        addToFavorites,
        setCurrentFavoriteData
    } = props

    const socket = useSocket()

    const { username } = useParams();

    const [isLiked, setIsLiked] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)
    const [chatId, setChatId] = useState(null)

    const [currentPhoto, setCurrentPhoto] = useState(null)

    const likeUnlike = () => {
        setIsLiked(prev => !prev);
        socket.emit("likeUnlike", {
            userId: user._id,
            liked_user_id: currentProfile._id
        })

        socket.once("likeUnlikeResponse", (response) => {
            if (!response.success) {
                setIsLiked(prev => !prev);
            }
        });
    }

    const favoriteUnfavorite = () => {
        setIsFavorite(!isFavorite)
        if (!isFavorite) {
            setCurrentFavoriteData(currentProfile)
        }
        addToFavorites({ user_id: user._id, favorite_user_id: currentProfile._id }).then(response => {
            if (!response) {
                setIsFavorite(!isFavorite)
            }
        })
    }

    useEffect(() => {
        getProfile(username)
    }, [username])

    useEffect(() => {
        if (currentProfile && socket && user) {
            socket.emit("visitProfile", {
                userId: currentProfile._id,
                visitor_user_id: user._id
            })
        }
    }, [currentProfile, socket, user])

    useEffect(() => {
        if (currentProfile) {
            if (currentProfile.is_liked) {
                setIsLiked(true)
            }
            if (currentProfile.is_favorite) {
                setIsFavorite(true)
            }
        }
    }, [currentProfile])

    useEffect(() => {
        return () => {
            setCurrentProfileData(null)
        }
    }, [])

    useEffect(() => {
        if (user && currentProfile) {
            socket.emit("joinRoom", {
                userId: user._id,
                companionId: currentProfile._id
            })
        }
    }, [user, currentProfile])

    useEffect(() => {
        if (socket) {
            socket.once("joinRoomSuccess", (response) => {
                if (response.chatId) {
                    setChatId(response.chatId)
                }
            });
        }
    }, [socket])

    return (
        <>
            {currentPhoto && (
                <SendMessageModal
                    onClose={() => setCurrentPhoto(null)}
                    photo={currentPhoto}
                    user={user}
                    currentProfile={currentProfile}
                    chatId={chatId}
                />
            )}
            {currentProfile && (
                <Profile
                    currentProfile={currentProfile}
                    likeUnlike={likeUnlike}
                    favoriteUnfavorite={favoriteUnfavorite}
                    isLiked={isLiked}
                    isFavorite={isFavorite}
                    setCurrentPhoto={setCurrentPhoto}
                    chatId={chatId}
                />
            )}
        </>
    )
}

let mapStateToProps = (state) => ({
    user: state.user.user,
    currentProfile: state.profiles.currentProfile
})

export default connect(mapStateToProps, {
    getProfile,
    setCurrentProfileData,
    addToFavorites,
    setCurrentFavoriteData
})(ProfileContainer)