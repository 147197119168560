import classes from './MainUserInfoProgress.module.css'

import man_empty from "../../../Assets/empty-male.jpeg"
import female_empty from "../../../Assets/empty-female.jpg"
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAddAPhoto } from "react-icons/md";

const MainUserInfoProgress = (props) => {
    const { user, handlePhotoModal } = props

    const { t } = useTranslation()

    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const progressFields = {
            email: user.email,
            username: user.username,
            photos: user.photos.lenght,
            about_me: user.about_me,
            preferences: user.preferences.lenght,
            looking_for: user.facts?.looking_for,
            relationship: user.facts?.relationship,
            height: user.facts?.height,
            body: user.facts?.body,
            smoker: user.facts?.smoker,
            is_verified: user.is_verified
        }

        const filledFields = Object.values(progressFields).filter(field => {
            if (Array.isArray(field)) {
                return field.length > 0;
            }
            return field !== null && field !== undefined && field !== '';
        }).length;
        
        setProgress((filledFields / Object.keys(progressFields).length) * 100);
    }, [user])

    return (
        <>
            <div className={classes.main}>
                <div
                    className={classes.userPhoto}
                    onClick={handlePhotoModal}
                >
                    {user.photos.length > 0 ? (
                        <img src={user.photos[0]} alt={user.username}/>
                    ) : (
                        <img src={user.gender === "man" ? man_empty : female_empty} alt="user empty photo"/>
                    )}
                </div>
                <div className={classes.progressContainer}>
                    <p className={classes.username}>{user.username}</p>
                    <div className={classes.progress}>
                        <div
                            className={classes.progressBar}
                            style={{
                                width: `${progress}%`
                            }}
                        />
                    </div>
                    <div className={classes.sub}>
                        <p className={classes.progressSub}>{t("myProfile.profile")} - {t("myProfile.progress")}</p>
                        <p className={classes.progressSub}>{Math.round(progress)}%</p>
                    </div>
                </div>
                <button 
                    className={classes.addPhotoBut}
                    onClick={handlePhotoModal}
                >
                    <MdAddAPhoto/>
                </button>
            </div>
            <div className={classes.mainMobile}>
                <div className={classes.mobileContainer}>
                    <div
                        className={classes.userPhoto}
                        onClick={handlePhotoModal}
                    >
                        {user.photos.length > 0 ? (
                            <img src={user.photos[0]} alt={user.username}/>
                        ) : (
                            <img src={user.gender === "man" ? man_empty : female_empty} alt="user empty photo"/>
                        )}
                    </div>
                    <button 
                        className={classes.addPhotoBut}
                        onClick={handlePhotoModal}
                    >
                        <MdAddAPhoto/>
                    </button>
                </div>
                <div className={classes.progressContainer}>
                    <p className={classes.username}>{user.username}</p>
                    <div className={classes.progress}>
                        <div
                            className={classes.progressBar}
                            style={{
                                width: `${progress}%`
                            }}
                        />
                    </div>
                    <div className={classes.sub}>
                        <p className={classes.progressSub}>{t("myProfile.profile")} - {t("myProfile.progress")}</p>
                        <p className={classes.progressSub}>{Math.round(progress)}%</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainUserInfoProgress