import { useTranslation } from 'react-i18next'
import classes from './ChatList.module.css'
import ChatListItem from './ChatListItem/ChatListItem'

const ChatList = (props) => {
    const {
        chats,
        currentChat,
        setCurrentChat,
        handleDelete
    } = props

    const { t } = useTranslation()

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <h5>{t("chat.chatListTitle")}</h5>
            </div>
            <div className={classes.wrapper}>
                {chats.map(el => (
                    <ChatListItem
                        currentChat={currentChat}
                        setCurrentChat={setCurrentChat}
                        key={el._id}
                        item={el}
                        handleDelete={handleDelete}
                    />
                ))}
            </div>
        </div>
    )
}

export default ChatList