export const ROUTES = {
    client: {
        home: "/",
        signup: "/sign-up",
        login: "/login",
        forgotPass: "/forgot-password",
        languages: "/languages",
        faq: "/support",
        faqReport: "/faq#reportProfile",
        privacyPolicy: "/privacy",
        terms: "/terms",
        compliance: "/compliance",
        chats: "/chats",
        inbox: "/inbox",
        imprint: "/imprint",
        favorites: "/favorites",
        likes: "/likes",
        visitors: "/visitors",
        myProfile: "/my-profile",
        profile: "/profile"
    }
}

export const THEME = {
    primaryColor: "#a10000"
}

export const PREFERENCES = [
    "body_jewelry",
    "vinyl_leather_latex",
    "lingerie_nylon",
    "golden_shower",
    "transgender",
    "role_playing",
    "foot_fetish",
    "intimate_hair",
    "sm_bdsm",
    "redhead",
    "sex_toys",
    "grannies",
    "anal_sex",
    "fisting",
    "oral_sex",
    "swinger",
    "glasses",
    "breasts"
]

export const LOOKING_FOR = [
    "no_answer",
    "man",
    "woman",
    "any"
]

export const RELATIONSHIP = [
    "no_answer",
    "single",
    "relationship",
    "open_relationship",
    "married"
]

export const BODDIES = [
    "no_answer",
    "slim",
    "normal",
    "athletic",
    "muscular",
    "chubby"
]

export const SMOKER = [
    "no_answer",
    "non_smoker",
    "ex_smoker",
    "occasional_smoker",
    "regular_smoker"
]

export const LANGUAGES = [
    {
        code: "en",
        title: "United Kingdom - English"
    },
    {
        code: "ua",
        title: "Україна - українська"
    },
]