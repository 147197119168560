import { connect } from "react-redux"
import classes from "./ChatModals.module.css"
import ChatModal from "./ChatModal/ChatModal"
import { setIsBuySubscribeOpen } from "../../../Redux/commonReducer"
import { setChatData, setChatsData } from "../../../Redux/chatsReducer"
import { useEffect, useState } from "react"
import { useSocket } from "../../Common/SocketProvider"

const ChatModals = (props) => {
    const { chats, chat, user, setIsBuySubscribeOpen, setChatData, setChatsData } = props

    const socket = useSocket()

    const [localChats, setLocalChats] = useState([])

    const handleClose = (id) => {
        setLocalChats(localChats.filter(el => el._id !== id))
    }

    useEffect(() => {
        if (chat) {
            const chatIndex = chats.findIndex(el => el._id === chat._id)
            const newChats = [...chats]
            newChats[chatIndex] = chat
            setChatsData(newChats)
        }
    }, [chat])

    useEffect(() => {
        if (socket) {
            socket.on('visitProfileNewChat', (response) => {
                const newChat = response.chat
                setChatsData([...chats, newChat])
                // setChatData({ ...chat, messages: newMessages })
            });
        }
    }, [socket])

    useEffect(() => {
        setLocalChats(chats)
    }, [chats])

    return (
        <div className={classes.main}>
            {localChats.filter(ch => ch.unreadCount > 0).splice(0, 3).map(el => (
                <ChatModal
                    key={el._id}
                    chat={el}
                    user={user}
                    setIsBuySubscribeOpen={setIsBuySubscribeOpen}
                    setChatData={setChatData}
                    handleClose={handleClose}
                />
            ))}
        </div>
    )
}

let mapStateToProps = (state) => ({
    chats: state.chats.chats,
    chat: state.chats.chat,
    user: state.user.user
})

export default connect(mapStateToProps, {
    setIsBuySubscribeOpen,
    setChatData,
    setChatsData
})(ChatModals)