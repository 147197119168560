import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import classes from "./Styles.module.css";

const Terms = () => {
    const { t } = useTranslation();

    return (
        <div className={classes.main}>
            <ReactMarkdown>{t("terms")}</ReactMarkdown>
        </div>
    );
};

export default Terms;
