import { connect } from "react-redux"
import { setCurrentFavoriteData } from "../../../../Redux/actionsReducer"
import { getDefaultUserPhoto } from "../../../../Utils/getDefaultUserPhoto"
import CtaModal from "../../../UI/CtaModal/CtaModal"
import { BsFillStarFill } from "react-icons/bs";
import classes from "./NowFavoriteModal.module.css"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../Utils/constants";
import { createChat } from "../../../../Redux/chatsReducer";

const NowFavoriteModal = (props) => {
    const { setCurrentFavoriteData, currentFavorite, createChat, user } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleStartChat = () => {
        createChat({
            userId: user._id,
            companionId: currentFavorite._id
        }).then(chatId => {
            if (chatId) {
                navigate(`${ROUTES.client.chats}/${chatId}`)
                setCurrentFavoriteData(null)
            }
        })
    }

    return (
        <CtaModal onClose={() => setCurrentFavoriteData(null)}>
            <div className={classes.main}>
                <div className={classes.photo}>
                    <img
                        src={currentFavorite.photos.length > 0 ? currentFavorite.photos[0] : getDefaultUserPhoto(currentFavorite.gender)}
                        alt={currentFavorite.username}
                    />
                    <BsFillStarFill/>
                </div>
                <p className={classes.name}>{currentFavorite.username}</p>
                <p className={classes.text}>{t("modals.currentFavorite.message")}</p>
                <button
                    className={classes.cta}
                    onClick={handleStartChat}
                >
                    {t("modals.currentFavorite.cta")}
                </button>
            </div>
        </CtaModal>
    )
}

export default connect(null, {
    setCurrentFavoriteData,
    createChat
})(NowFavoriteModal)