import classes from "./Preferences.module.css"
import { FaRegEdit } from "react-icons/fa";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PREFERENCES } from "../../../../Utils/constants";
import PreferenceItem from "./PreferenceItem/PreferenceItem";
import { connect } from "react-redux";
import { updateProfile } from "../../../../Redux/userReducer";

const Preferences = (props) => {
    const { user, updateProfile, isMe = true } = props

    const { t } = useTranslation()

    const [selectedPreferences, setSelectedPreferences] = useState(user.preferences)

    const [isEditMode, setIsEditMode] = useState(false)

    const handleEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const onClickPreference = (preference) => {
        if (isMe) {
            setIsEditMode(true)
            if (selectedPreferences.includes(preference)) {
                setSelectedPreferences(selectedPreferences.filter(el => el !== preference))
            } else {
                setSelectedPreferences([...selectedPreferences, preference])
            }
        }
    }

    const onSaveClick = () => {
        updateProfile(user.username, { preferences: selectedPreferences })
            .then(response => {
                if (response) {
                    handleEditMode()
                }
            })
    }

    return (
        <div className={`${classes.main} ${isMe ? "" : classes.notMe}`}>
            <div className={classes.header}>
                <h4>{t("myProfile.preferences")}</h4>
                {isMe && (
                    <button onClick={handleEditMode}>
                        <FaRegEdit/>
                    </button>
                )}
            </div>
            <div className={classes.wrapper}>
                {PREFERENCES.map(el => (
                    <PreferenceItem
                        key={el}
                        isSelected={selectedPreferences.includes(el)}
                        text={el}
                        onClick={onClickPreference}
                        isMe={isMe}
                    />
                ))}
            </div>
            {isEditMode && (
                <div className={classes.footer}>
                    <div className={classes.buttons}>
                        <button
                            className={classes.action}
                            onClick={() => setSelectedPreferences(PREFERENCES)}
                            type="button"
                        >
                            {t("sidebar.select_all")}
                        </button>
                        <button
                            className={classes.action}
                            onClick={() => setSelectedPreferences([])}
                            type="button"
                        >
                            {t("sidebar.none")}
                        </button>
                    </div>
                    <div className={classes.buttons}>
                        <button
                            className={classes.cancel}
                            onClick={handleEditMode}
                            type="button"
                        >
                            {t("myProfile.abort")}
                        </button>
                        <button
                            className={classes.submit}
                            type="button"
                            onClick={onSaveClick}
                        >
                            {t("myProfile.save")}
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(null, {
    updateProfile
})(Preferences)