import { getDefaultUserPhoto } from "../../../../Utils/getDefaultUserPhoto"
import classes from "./Message.module.css"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { ROUTES } from "../../../../Utils/constants"

const Message = (props) => {
    const { item, companion, user, setCurrentPhoto, type = "chat" } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const isMe = item.user_id === user._id

    const today = moment().startOf('day')

    const isToday = moment(item.created_at).isSame(today, 'day')

    const current_date = isToday ? `${t("chat.at")} ${moment(item.created_at).format("HH:mm")}` : moment(item.created_at).format("DD.MM.YYYY HH:mm")

    const onImageClick = () => {
        navigate(`${ROUTES.client.profile}/${companion.username}`)
    }

    return (
        <div className={`${classes.main} ${isMe ? classes.me : ""} ${type === "small" ? classes.small : ""}`}>
            <img
                src={isMe ? (user.photos.length > 0 ? user.photos[0] : getDefaultUserPhoto(user.gender)) : (companion.photos.length > 0 ? companion.photos[0] : getDefaultUserPhoto(companion.gender))}
                className={classes.userPhoto}
                style={{ cursor: isMe ? "default" : "pointer" }}
                onClick={isMe ? undefined : onImageClick}
            />
            <div className={classes.message}>
                <p className={classes.dateTime}>{current_date}:</p>
                {item.type === "message" && <p className={classes.text}>{item.message}</p>}
                {item.type === "photo" && (
                    <img
                        src={item.message}
                        alt="photo"
                        className={classes.photo}
                        onClick={() => setCurrentPhoto ? setCurrentPhoto(item.message) : undefined}
                    />
                )}
            </div>
        </div>
    )
}

export default Message