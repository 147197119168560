import { useTranslation } from "react-i18next"
import classes from "./NotificationItem.module.css"
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../Utils/constants"
import { getDefaultUserPhoto } from "../../../Utils/getDefaultUserPhoto";

const NotificationItem = (props) => {
    const { item, onRemoveNotification } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const onClick = () => {
        if (item.type === "message") {
            navigate(`${ROUTES.client.chats}`)
        } else {
            navigate(`${ROUTES.client.profile}/${item.from_user_id.username}`)
        }
        onRemoveNotification(item._id)
    }

    return (
        <div className={classes.main}>
            <img onClick={onClick} className={classes.profilePhoto} src={item.from_user_id.photos.length > 0 ? item.from_user_id.photos[0] : getDefaultUserPhoto(item.from_user_id.gender)} alt="user photo"/>
            <div className={classes.content}>
                <div className={classes.info}>
                    <h6 onClick={onClick}>{item.from_user_id.username}</h6>
                    <p onClick={onClick}>{t(`notifications.${item.type}`)}</p>
                </div>
                <button
                    className={classes.remove}
                    onClick={() => onRemoveNotification(item._id)}
                >
                    <IoCloseSharp/>
                </button>
                <button className={classes.action} onClick={onClick}>
                    {item.type === "message" ? t("notifications.read") : t("notifications.view")}
                </button>
            </div>
        </div>
    )
}

export default NotificationItem