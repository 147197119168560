import { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { useSocket } from "../../../Components/Common/SocketProvider"
import { getChat, setChatData, deleteChat } from "../../../Redux/chatsReducer"
import { getProfiles } from "../../../Redux/profilesReducer"
import { ROUTES } from "../../../Utils/constants"
import Chat from "./Chat"

const ChatRoomContainer = (props) => {
    const {
        getChat,
        chat,
        setChatData,
        user,
        deleteChat,
        getProfiles,
        profiles
    } = props

    const socket = useSocket()

    const { id } = useParams()

    const navigate = useNavigate()

    const handleDelete = (chatId) => {
        deleteChat(chatId).then(response => {
            if (response) {
                navigate(ROUTES.client.home)
            }
        })
    }

    useEffect(() => {
        if (user && id) {
            getChat(id)
            getProfiles({
                count: 1,
                limit: 16,
                looking_for: user?.filter_settings?.looking_for || "",
                age_min: user?.filter_settings?.age_range?.min || "",
                age_max: user?.filter_settings?.age_range?.max || "",
                preferences: user?.filter_settings?.preferences.join(",") || "",
                randomize: true,
                current_user: user?.username || ""
            })
        }
    }, [user, id])

    useEffect(() => {
        if (user && chat !== null) {
            socket.emit("joinRoom", {
                userId: user._id,
                companionId: chat.participants[0]._id
            })

            socket.on('message', (message) => {
                if (message.chat_id === chat._id) {
                    const newMessages = [ ...chat.messages ]
                    newMessages.push(message)
        
                    setChatData({ ...chat, messages: newMessages })
                }
            });
        }
    }, [user, chat])

    return (
        <Chat
            chat={chat}
            handleDelete={handleDelete}
            profiles={profiles}
            id={id}
        />
    )
}

let mapStateToProps = (state) => ({
    chat: state.chats.chat,
    user: state.user.user,
    profiles: state.profiles.profiles,
})

export default connect(mapStateToProps, {
    getChat,
    setChatData,
    deleteChat,
    getProfiles
})(ChatRoomContainer)