import Overflow from "../../UI/Overflow/Overflow"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { IoCloseSharp } from "react-icons/io5";
import classes from "./ChatPhotoSliderModal.module.css"

const ChatPhotoSliderModal = (props) => {
    const { onClose, photos } = props

    return (
        <Overflow>
            <div className={classes.main}>
                <button
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <IoCloseSharp/>
                </button>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    loop={true}
                    navigation
                >
                    {photos.map(el => (
                        <SwiperSlide key={el}>
                            <img
                                src={el}
                                alt="Photo"
                                className={classes.slidePhoto}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </Overflow>
    )
}

export default ChatPhotoSliderModal