import { useEffect, useRef, useState } from "react"
import ChatPhotoSliderModal from "../ChatPhotoSliderModal/ChatPhotoSliderModal"
import Message from "./Message/Message"
import classes from "./Messages.module.css"

const Messages = (props) => {
    const { chat, user } = props

    const messages = chat.messages

    const [currentPhoto, setCurrentPhoto] = useState(null)

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className={classes.main}>
            {currentPhoto && (
                <ChatPhotoSliderModal
                    photos={[currentPhoto]}
                    onClose={() => setCurrentPhoto(null)}
                />
            )}
            {messages.map(el => (
                <Message
                    key={el._id}
                    item={el}
                    companion={chat.participants[0]}
                    user={user}
                    setCurrentPhoto={setCurrentPhoto}
                />
            ))}
            <div ref={messagesEndRef}/>
        </div>
    )
}

export default Messages