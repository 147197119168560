import ProfileItem from "../../Profile/ProfileItem/ProfileItem"
import classes from "./ProfilesWrapper.module.css"

const ProfilesWrapper = (props) => {
    const { profiles } = props

    return (
        <div className={classes.usersWrapper}>
            {profiles.map(el => (
                <ProfileItem key={el._id} item={el}/>
            ))}
        </div>
    )
}

export default ProfilesWrapper