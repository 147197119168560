import { useState, useCallback } from "react";
import CustomButton from "../../UI/CustomButton/CustomButton";
import Textarea from "../../UI/Form/Textarea";
import classes from "./ChatForm.module.css";
import { AiOutlineSend } from "react-icons/ai";
import { FaImage } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useSocket } from "../../Common/SocketProvider";
import { connect } from "react-redux";
import { setIsBuySubscribeOpen } from "../../../Redux/commonReducer";
import { setChatData } from "../../../Redux/chatsReducer";
import { chatApi } from "../../../Api/api";
import { IoCloseSharp } from "react-icons/io5";

const ChatForm = (props) => {
    const { chat, user, setIsBuySubscribeOpen, setChatData, type = "page" } = props;

    const socket = useSocket();
    const { t } = useTranslation();

    const [value, setValue] = useState("");
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const handleValue = (event) => {
        setValue(event.target.value);
    };

    const handleFile = useCallback((event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setPreview(reader.result);
                setFile(file);
            };

            reader.readAsDataURL(file);
        }
    }, []);

    const uploadPhoto = async () => {
        let response = await chatApi.uploadPhoto({
            photo: file,
            chatId: chat._id
        });

        socket.emit('newMessage', {
            chatId: chat._id, 
            userId: user._id, 
            messageContent: response.imgPath,
            messageType: 'photo'
        });
    };

    const removeFile = () => {
        setFile(null);
        setPreview(null);
    };

    const onSubmit = async () => {
        if (value || file) {
            const newMessages = [ ...chat.messages ];
            newMessages.push({
                message: value ? value : preview,
                user_id: user._id,
                is_loading: true,
                type: value ? "message" : "photo"
            });

            console.log(socket)

            if (chat) {
                setChatData({ ...chat, messages: newMessages });
            }

            if (file) {
                await uploadPhoto();
            } else {
                socket.emit('newMessage', {
                    chatId: chat._id, 
                    userId: user._id, 
                    messageContent: value,
                    messageType: 'message'
                });
            }

            // socket.once("messageResponse", (response) => {
            //     if (response.message) {
            //         newMessages[newMessages.length - 1] = response.message;
            //         if (chat._id === response.message.chat_id) {
            //             setChatData({ ...chat, messages: newMessages });
            //         }
            //     }
            // });

            socket.once("send_message_error", () => {
                setIsBuySubscribeOpen(true);
            });
            setValue('');
            setFile(null);
            setPreview(null);
        }
    };

    return (
        <form className={`${classes.main} ${type === "window" ? classes.window : ""}`}>
            <div className={classes.inputs}>
                {preview ? (
                    <div className={`${classes.previewContainer}`}>
                        <img src={preview} alt="preview" className={classes.preview} />
                        <button onClick={removeFile} type="button">
                            <IoCloseSharp />
                        </button>
                    </div>
                ) : (
                    <Textarea
                        onChange={handleValue}
                        value={value}
                        multiline
                        rows={type === "window" ? 1 : 3}
                        placeholder={t("chat.placeholder")}
                    />
                )}
            </div>
            <div className={classes.buttons}>
                <input type="file" hidden id="file" onChange={handleFile} />
                <label htmlFor="file">
                    <FaImage />
                </label>
                <CustomButton onClick={onSubmit} icon={<AiOutlineSend />} />
            </div>
        </form>
    );
};

export default connect(null, {
    setIsBuySubscribeOpen,
    setChatData
})(ChatForm);
