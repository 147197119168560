import { profilesApi } from "../Api/api"
import { setIsFetching, setServerError } from "./commonReducer"

const SET_PROFILES_DATA = "SET_PROFILES_DATA"
const SET_CURRENT_PROFILE_DATA = "SET_CURRENT_PROFILE_DATA" 
const SET_NEW_PROFILE_DATA = "SET_NEW_PROFILE_DATA"
const SET_TOTAL = "SET_TOTAL"

let initialState = {
    profiles: [],
    total: 0,
    currentProfile: null,
    newProfile: null
}

let profilesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PROFILES_DATA: {
            return { ...state, profiles: action.isInfinity 
                ? [...state.profiles, ...action.profiles]
                : action.profiles
            }
        }
        case SET_CURRENT_PROFILE_DATA: {
            return { ...state, currentProfile: action.currentProfile }
        }
        case SET_NEW_PROFILE_DATA: {
            return { ...state, newProfile: action.newProfile }
        }
        case SET_TOTAL: {
            return { ...state, total: action.total }
        }
        default: {
            return state
        }
    }
}

export const setProfilesData = (profiles, isInfinity = false) => ({
    type: SET_PROFILES_DATA, profiles, isInfinity
})
export const setTotalProfiles = (total) => ({
    type: SET_TOTAL, total
})
export const setCurrentProfileData = (currentProfile) => ({
    type: SET_CURRENT_PROFILE_DATA, currentProfile
})

export const getProfiles = (data) => async (dispatch) => {
    if (!data.isInfinity) {
        dispatch(setIsFetching(true))
    }
    try {
        let response = await profilesApi.getProfiles(data)
        dispatch(setProfilesData(response.users, data.isInfinity))
        dispatch(setTotalProfiles(response.total))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const getProfile = (username) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await profilesApi.getProfile(username)
        dispatch(setCurrentProfileData(response.user))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export default profilesReducer