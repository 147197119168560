import classes from "./Footer.module.css"
import { TbWorld } from "react-icons/tb";
import { useTranslation } from 'react-i18next'
import Link from "../../UI/Text/Link/Link";
import { LANGUAGES, ROUTES } from "../../../Utils/constants";
import i18n from "../../../Utils/i18n";

const Footer = () => {
    const { t } = useTranslation()

    return (
        <footer className={classes.footer}>
            <p>{t("footer.text1")}</p>
            <div className={classes.links}>
                <Link text={
                    <>
                        <TbWorld/>
                        <span>{LANGUAGES.find(el => el.code === i18n.language).title}</span>
                    </>
                } to={ROUTES.client.languages}/>
                <div className={classes.divider}/>
                <Link text={t("footer.links.support")} to={ROUTES.client.faq}/>
                <div className={classes.divider}/>
                <Link text={t("footer.links.report")} to={ROUTES.client.faqReport}/>
                <div className={classes.divider}/>
                <Link text={t("footer.links.imprint")} to={ROUTES.client.imprint}/>
                <div className={classes.divider}/>
                <Link text={t("footer.links.privacyPolicy")} to={ROUTES.client.privacyPolicy}/>
                <div className={classes.divider}/>
                <Link text={t("footer.links.terms")} to={ROUTES.client.terms}/>
            </div>
            <Link text="18 U.S.C. 2257 Compliance Statement" to={ROUTES.client.compliance}/>
            <p>Abacus online Global k.f.t, Miklos u.13 VIII/42, 1033 Budapest, Hungary</p>
            <p>© {new Date().getFullYear()} ExtremeChat</p>
        </footer>
    )
}

export default Footer