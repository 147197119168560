import { useTranslation } from "react-i18next"
import classes from "./PreferenceItem.module.css"
import { IoCheckmarkSharp } from "react-icons/io5";

const PreferenceItem = (props) => {
    const { isSelected, onClick, text, isMe = true } = props

    const { t } = useTranslation()

    return (
        <div
            className={`${classes.main} ${isSelected ? classes.selected : ""} ${isMe ? "" : classes.notMe}`}
            onClick={() => onClick(text)}
        >
            <p>{t(`sidebar.preferences_items.${text}`)}</p>
            <div className={classes.circle}>
                <IoCheckmarkSharp/>
            </div>
        </div>
    )
}

export default PreferenceItem