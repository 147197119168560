import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { updateProfile } from "../../../Redux/userReducer"
import Modal from "../../UI/Modal/Modal"
import PhotoButton from "./PhotoButton/PhotoButton"
import classes from "./PhotoUploader.module.css"

const PhotoUploader = (props) => {
    const { onClose, user, updateProfile } = props

    const { t } = useTranslation()

    const [photos, setPhotos] = useState(user.photos)

    const ref = useRef(null)

    const onAddClick = () => {
        ref.current.click()
    }

    const onChange = (event) => {
        const files = Array.from(event.target.files); // Get the selected files
        const existingPhotos = [...photos] || []; // Get the existing URLs from the user's profile

        // Append new files to the end of the existing URLs
        const combinedPhotos = [...existingPhotos, ...files];

        // Update the profile with the combined array
        updateProfile(user.username, { photos: combinedPhotos });
    }

    const onRemove = (photo) => {
        const filtered = photos.filter(el => el !== photo)
        setPhotos(filtered)
        if (filtered.length > 0) {
            updateProfile(user.username, { photos: filtered });
        } else {
            updateProfile(user.username, { photos: filtered, isEmptyPhotos: true });
        }
    }

    useEffect(() => {
        setPhotos(user?.photos)
    }, [user])

    return (
        <Modal onClose={onClose} title={t("myProfile.photoTitle")}>
            <div className={classes.main}>
                <p>{t("myProfile.photoText")}</p>
                <div className={classes.buttons}>
                    {Array.from({ length: 5 }).map((el, index) => (
                        <PhotoButton
                            key={index}
                            size={index === 0 ? "big" : "small"}
                            image={photos[index]}
                            user={user}
                            onClick={onAddClick}
                            onRemove={onRemove}
                        />
                    ))}
                </div>
                <input
                    onChange={onChange}
                    hidden
                    ref={ref}
                    type="file"
                />
            </div>
        </Modal>
    )
}

export default connect(null, {
    updateProfile
})(PhotoUploader)