import React, { useState, useEffect, useRef } from 'react';

const InfiniteScroll = (props) => {
    const { page, setPage, fetchData, className, children } = props

    const [loading, setLoading] = useState(false);
    const loader = useRef(null);

    useEffect(() => {
        loadItems();
    }, [page]);

    const loadItems = async () => {
        setLoading(true);
        // Fetch data from an API or database
        await fetchData(true);
        setLoading(false);
    };

    // Intersection Observer for infinite scrolling
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            },
            { threshold: 1 }
        );
        if (loader.current) {
            observer.observe(loader.current);
        }
        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        };
    }, []);

    return (
        <div className={className}>
            {children}
            {loading && <p>Loading...</p>}
            <div ref={loader} />
        </div>
    );
};

export default InfiniteScroll;
