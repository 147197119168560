import classes from './UsernameLocationForm.module.css'
import { CiUser } from "react-icons/ci";
import { useTranslation } from 'react-i18next';
import { RiArrowDropUpLine } from "react-icons/ri";
import { useEffect, useState } from 'react';
import { Collapse, MenuItem } from '@mui/material';
import { FaRegEdit } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form"
import Input from '../../UI/Form/Input';
import Select from '../../UI/Form/Select/Select';
import { connect } from 'react-redux';
import { updateProfile } from '../../../Redux/userReducer';
import Text from '../../UI/Text/Text/Text';
import AboutMeForm from '../AboutMeForm/AboutMeForm';

const start = 18;
const end = 99;
const ageArray = Array.from({ length: end - start + 1 }, (v, i) => i + start);

const UsernameLocationForm = (props) => {
    const { user, updateProfile, serverError } = props

    const { t } = useTranslation()

    const { handleSubmit, reset, control } = useForm()

    const [isOpen, setIsOpen] = useState(true)
    const [isEditMode, setIsEditMode] = useState(false)

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const onSubmit = (data) => {
        updateProfile(user.username, data).then(response => {
            if (response) {
                handleEditMode()
            }
        })
    }

    useEffect(() => {
        if (isEditMode) {
            reset({
                username: user.username,
                age: user.age,
                location: user.location
            })
        }
    }, [isEditMode])

    return (
        <div className={classes.main}>
            <div
                className={`${classes.header} ${isOpen ? classes.open : ""}`}
                onClick={handleOpen}
            >
                <div className={classes.headerTitle}>
                    <CiUser/>
                    <h4>{t("myProfile.generalInfo")}</h4>
                </div>
                <span>
                    <RiArrowDropUpLine/>
                </span>
            </div>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {!isEditMode ? (
                    <div className={classes.static}>
                        <div className={classes.staticInfo}>
                            <p>{user.username}, {user.age}
                            {/* , <span>{t("sidebar.from")} {user.location}</span> */}
                            </p>
                        </div>
                        <button onClick={handleEditMode}>
                            <FaRegEdit/>
                        </button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <div className={classes.container}>
                            <Controller
                                name="username"
                                control={control}
                                defaultValue=""
                                rules={{ required: t("errors.required") }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className={classes.field}>
                                        <label>{t("myProfile.username")}</label>
                                        <Input
                                            placeholder={t("auth.username")}
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                        />
                                        {serverError && <Text type="error" text={t(`errors.${serverError}`)}/>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="age"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className={classes.field}>
                                        <label>{t("auth.signup.age")}</label>
                                        <Select
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                        >
                                            {ageArray.map(el => (
                                                <MenuItem
                                                    key={el}
                                                    value={el}
                                                >
                                                    {el}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                            />
                            <Controller
                                name="location"
                                control={control}
                                defaultValue=""
                                rules={{ required: t("errors.required") }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className={classes.field}>
                                        <label>{t("myProfile.location")}</label>
                                        <Input
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className={classes.buttons}>
                            <button
                                className={classes.cancel}
                                onClick={handleEditMode}
                                type="button"
                            >
                                {t("myProfile.abort")}
                            </button>
                            <button className={classes.submit} type="submit">{t("myProfile.save")}</button>
                        </div>
                    </form>
                )}
                <AboutMeForm user={user}/>
            </Collapse>
        </div>
    )
}

let mapStateToProps = (state) => ({
    serverError: state.common.serverError
})

export default connect(mapStateToProps, {
    updateProfile
})(UsernameLocationForm)