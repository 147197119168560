import { connect } from "react-redux"
import classes from "./ChatComponent.module.css"
import ChatForm from "./ChatForm/ChatForm"
import ChatHeader from "./ChatHeader/ChatHeader"
import Messages from "./Messages/Messages"

const ChatComponent = (props) => {
    const { chat, user, handleDelete, id } = props

    return (
        <div className={classes.main}>
            <ChatHeader chat={chat} handleDelete={handleDelete} id={id}/>
            <Messages chat={chat} user={user}/>
            <ChatForm chat={chat} user={user}/>
        </div>
    )
}

let mapStateToProps = (state) => ({
    user: state.user.user
})

export default connect(mapStateToProps, {

})(ChatComponent)