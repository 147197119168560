import classes from "./Header.module.css"

const Header = (props) => {
    const { type, text, className } = props

    return (
        <>
            {type === "h1" && (
                <h1 className={`${classes.h1} ${className}`}>{text}</h1>
            )}
            {type === "h2" && (
                <h2 className={`${classes.h2} ${className}`}>{text}</h2>
            )}
            {type === "h3" && (
                <h3 className={`${classes.h3} ${className}`}>{text}</h3>
            )}
            {type === "h4" && (
                <h4 className={`${classes.h4} ${className}`}>{text}</h4>
            )}
            {type === "h5" && (
                <h5 className={`${classes.h5} ${className}`}>{text}</h5>
            )}
        </>
    )
}

export default Header