import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    "& input::placeholder": {
        color: "white"
    },
    "& label.Mui-focused": {
        color: "white",
        // fontFamily: "Helvetica"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
            border: "none"
        },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiInputLabel-root": {
        color: "gray",
        // fontFamily: "Helvetica"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: 0,
        color: "white",
        background: "var(--bg)",
        height: "50px"
        // fontFamily: "Helvetica"
    },
    "& .MuiFormHelperText-root.Mui-error": {
        marginTop: "8px",
        marginLeft: 0
    }
}));


const Input = (props) => {
    const { error } = props;

  return (
    <CustomTextField
        variant="outlined"
        error={!!error}
        helperText={error ? error.message : null}
        {...props}
    />
  );
}

export default Input