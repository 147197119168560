import classes from "./Select.module.css"
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material"

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    "& svg": {
        color: "white"
    },
    '& .MuiMenuItem-root .MuiMenuItem-gutters .MuiButtonBase-root': {
        display: "flex !important",
    },
    '& .MuiMenuItem-root': {
        display: "flex",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: "none"
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        color: "white",
        background: "var(--bg)",
        textAlign: "left",
        height: "50px"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: "none"
    },
    '& .MuiFormHelperText-root.Mui-error': {
        margin: 0,
        marginTop: "8px"
    },
    '& .MuiList-root .MuiList-padding .MuiMenu-list': {
        display: "flex",
        flexDirection: "column"
    }
}));

const Select = (props) => {
    const { label, error, children } = props

    return (
        <div className={classes.main}>
            {label && <label>{label}</label>}
            <CustomTextField
                variant="outlined"
                error={!!error}
                select
                SelectProps={{
                    displayEmpty: true,
                }}
                helperText={error ? error.message : null}
                {...props}
                label=""
            >
                {children}
            </CustomTextField>
        </div>
    )
}

export default Select