import classes from "./Text.module.css"

const Text = (props) => {
    const { type, text } = props

    return (
        <>
            {type === "label" && (
                <label className={classes.label}>{text}</label>
            )}
            {type === "error" && (
                <p className={classes.error}>{text}</p>
            )}
        </>
    )
}

export default Text