import AuthClientLayout from "../../../Components/Auth/Client/AuthClientLayout/AuthClientLayout"
import AuthModal from "../../../Components/Auth/Client/AuthModal/AuthModal"
import Header from "../../../Components/UI/Text/Header/Header"
import classes from "./Auth.module.css"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import Input from "../../../Components/UI/Form/Input"
import CustomButton from "../../../Components/UI/CustomButton/CustomButton"
import Link from "../../../Components/UI/Text/Link/Link"
import { ROUTES } from "../../../Utils/constants"
import { login } from "../../../Redux/userReducer"
import { connect } from "react-redux"
import Text from "../../../Components/UI/Text/Text/Text"
import { setServerError } from "../../../Redux/commonReducer"
import { useEffect } from "react"
import { useNavigate } from "react-router"

const Login = (props) => {
    const { login, serverError, setServerError, isAuth } = props
    
    const { t } = useTranslation()

    const navigate = useNavigate()

    const { handleSubmit, control } = useForm()

    const onSubmit = (data) => {
        setServerError(null)
        login(data)
    }

    useEffect(() => {
        if (isAuth) {
            navigate(localStorage.previousPath)
        }
    }, [isAuth])

    useEffect(() => {
        return () => setServerError(null)
    }, [])

    return (
        <AuthClientLayout>
            <AuthModal>
                <div className={classes.content}>
                    <Header type="h3" text={t("auth.login.title")}/>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <Controller
                            name="username"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("errors.required") }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    placeholder={t("auth.username")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t("errors.required"),
                                pattern: {
                                    value: /^[a-zA-Z0-9]{4,}$/,
                                    message: t("errors.invalidPassword")
                                }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    placeholder={t("auth.password")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    type="password"
                                />
                            )}
                        />
                        {serverError && <Text type="error" text={t(`errors.${serverError}`)}/>}
                        <div className={classes.submit}>
                            <Link to={ROUTES.client.forgotPass} text={t("auth.forgotPass.text")}/>
                            <CustomButton type="submit" text={t("auth.login.submit")}/>
                        </div>
                    </form>
                </div>
            </AuthModal>
        </AuthClientLayout>
    )
}

let mapStateToProps = (state) => ({
    serverError: state.common.serverError,
    isAuth: state.user.isAuth
})

export default connect(mapStateToProps, {
    login,
    setServerError
})(Login)