import { useEffect } from "react"
import { connect } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router"
import ChatModals from "../../Components/Chat/ChatModals/ChatModals"
import Preloader from "../../Components/Common/Preloader/Preloader"
import SocketProvider from "../../Components/Common/SocketProvider"
import Layout from "../../Components/UI/Layout/Layout"
import { me } from "../../Redux/userReducer"
import { ROUTES } from "../../Utils/constants"

const ClientPage = (props) => {
    const {
        me,
        isAuth,
        isFetching,
        user
    } = props

    const { pathname } = useLocation()

    const isShowChats = !pathname.includes(ROUTES.client.chats) && !pathname.includes(ROUTES.client.login) && !pathname.includes(ROUTES.client.signup)

    useEffect(() => {
        if(localStorage.accessToken) {
            me()
        }
    }, [localStorage.accessToken, isAuth])

    useEffect(() => {
        localStorage.previousPath = pathname
    }, [pathname])

    if (isFetching && !user) {
        return <Preloader />
      }

    if (!isAuth || !user) {
        return <Navigate to={ROUTES.client.login} replace />
    }

    return (
        <SocketProvider>
            {isShowChats && <ChatModals/>}
            <Layout>
                <Outlet/>
            </Layout>
        </SocketProvider>
    )
}

let mapStateToProps = (state) => ({
    isAuth: state.user.isAuth,
    isFetching: state.common.isFetching,
    user: state.user.user
})

export default connect(mapStateToProps, {
    me
})(ClientPage)