import OnlineTag from "../../../Profile/OnlineTag/OnlineTag"
import classes from "./ChatListItem.module.css"
import { FaRegTrashAlt } from "react-icons/fa";
import { getDefaultUserPhoto } from "../../../../Utils/getDefaultUserPhoto";
import useScreenWidth from "../../../../Hooks/useScreenWidth";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../Utils/constants";

const ChatListItem = (props) => {
    const { item, currentChat, setCurrentChat, handleDelete } = props

    const screenWidth = useScreenWidth()

    const navigate = useNavigate()

    const user = item.participants[0]

    const onClickDelete = (event) => {
        event.preventDefault()
        event.stopPropagation()
        handleDelete(item._id)
    }

    const handleClickChat = () => {
        if (screenWidth > 767) {
            setCurrentChat(item)
        } else {
            navigate(`${ROUTES.client.chats}/${item._id}`)
        }
    }

    return (
        <div
            className={`${classes.main} ${currentChat?._id === item._id ? classes.active : ""}`}
            onClick={handleClickChat}
        >
            <div className={classes.info}>
                <div className={classes.image}>
                    <img src={user.photos.length > 0 ? user.photos[0] : getDefaultUserPhoto(user.gender)}/>
                    <div className={classes.online}>
                        <OnlineTag isOnline={user.is_online} size={"big"}/>
                    </div>
                </div>
                <div className={classes.name}>
                    <p>{user.username}, {user.age}</p>
                    <p>&lt; {user.distance}km</p>
                </div>
            </div>
            {item.unreadCount > 0 && (
                <div className={classes.counter}>
                    <p>{item.unreadCount}</p>
                </div>
            )}
            <button
                className={classes.removeBut}
                onClick={onClickDelete}
            >
                <FaRegTrashAlt/>
            </button>
        </div>
    )
}

export default ChatListItem