import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import RoutesComponent from './Components/Common/RoutesComponent';
import Preloader from './Components/Common/Preloader/Preloader';
import { connect } from 'react-redux';
import { getNotifications } from './Redux/actionsReducer';
import { useEffect, useState } from 'react';
import NowFavoriteModal from './Components/Common/InfoModals/NowFavoriteModal/NowFavoriteModal';

function App(props) {
  const { isFetching, getNotifications, user, currentFavorite } = props

  const [isFirstLoading, setIsFirstLoading] = useState(true)

  useEffect(() => {
    if (user && isFirstLoading) {
      getNotifications()
      setIsFirstLoading(false)
    }
  }, [user, isFirstLoading])

  return (
    <Router>
      <div className="App">
        {isFetching && <Preloader zIndex={20}/>}
        <RoutesComponent/>
        {currentFavorite && (
          <NowFavoriteModal
            currentFavorite={currentFavorite}
            user={user}
          />
        )}
      </div>
    </Router>
  );
}

let mapStateToProps = (state) => ({
  isFetching: state.common.isFetching,
  currentFavorite: state.actions.currentFavorite,
  user: state.user.user
})

export default connect(mapStateToProps, {
  getNotifications
})(App);
