import { connect } from "react-redux"
import MyProfile from "./MyProfile"
import { useState } from "react"
import PhotoUploader from "../../../Components/Common/PhotoUploader/PhotoUploader"

const MyProfileContainer = (props) => {
    const {
        user,
        likes,
        visitors,
        chats
    } = props

    const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false)

    const newMessages = chats.map(el => el.unreadCount ? el.unreadCount : 0).reduce((a, b) => a + b, 0)

    const handlePhotoModal = () => {
        setIsOpenPhotoModal(!isOpenPhotoModal)
    }

    return (
        <>
            <MyProfile
                user={user}
                likes={likes}
                visitors={visitors}
                handlePhotoModal={handlePhotoModal}
                newMessages={newMessages}
            />
            {isOpenPhotoModal && (
                <PhotoUploader
                    onClose={handlePhotoModal}
                    user={user}
                />
            )}
        </>
    )
}

let mapStateToProps = (state) => ({
    user: state.user.user,
    likes: state.actions.likes,
    visitors: state.actions.visitors,
    chats: state.chats.chats
})

export default connect(mapStateToProps, {
})(MyProfileContainer)