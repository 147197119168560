import { useNavigate } from "react-router"
import { ROUTES } from "../../../../Utils/constants"
import CustomButton from "../../../UI/CustomButton/CustomButton"
import classes from "./AuthClientLayout.module.css"
import Footer from "../../../Common/Footer/Footer"

const AuthClientLayout = (props) => {
    const { children } = props

    const navigate = useNavigate()

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <h2>INTIMEET</h2>
                <div className={classes.buttons}>
                    <CustomButton
                        text="Login"
                        onClick={() => navigate(ROUTES.client.login)}
                    />
                    <CustomButton
                        text="Register"
                        onClick={() => navigate(ROUTES.client.signup)}
                    />
                </div>
            </div>
            <div className={classes.content}>
                {children}
            </div>
            <Footer/>
        </div>
    )
}

export default AuthClientLayout