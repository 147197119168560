import classes from "./Navbar.module.css"
import { NavLink } from 'react-router-dom'
import { ROUTES } from "../../../Utils/constants"
import Container from "../../UI/Container/Container"
import { IoMenuSharp } from "react-icons/io5";
import { Menu } from "@mui/material";
import { useState } from "react";
import { CiUser } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { GrLogout } from "react-icons/gr";
import { connect } from "react-redux";
import { logout } from "../../../Redux/userReducer"
import { setIsBuySubscribeOpen } from "../../../Redux/commonReducer";
import { useSocket } from "../SocketProvider";
import MainNavigation from "../../UI/Layout/MainNavgation/MainNavigation";
import { useNavigate } from "react-router"

const Navbar = (props) => {
    const { logout, setIsBuySubscribeOpen, user, setIsOpenSearchMobile } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const socket = useSocket()

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLogout = () => {
        socket.disconnect();
        logout()
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
      const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickSearch = () => {
        setIsOpenSearchMobile(prev => !prev)
        navigate(ROUTES.client.home)
    }

    return (
        <div className={classes.main}>
            <Container className={classes.container}>
                <NavLink className={classes.logo} to={ROUTES.client.home}>
                    <p>INTIMEET</p>
                </NavLink>
                <div className={classes.right}>
                    {!user?.is_subscribed && (
                        <button
                            className={classes.buySub}
                            onClick={() => setIsBuySubscribeOpen(true)}
                        >
                            {t("actions.buySubscribe")}
                        </button>
                    )}
                    <div
                        className={classes.menu}
                        onMouseEnter={handleClick}
                        onMouseLeave={handleClose}
                    >
                        <div className={classes.burger}>
                            <IoMenuSharp/>
                        </div>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            disableAutoFocusItem
                            onClose={handleClose}
                            sx={{
                                "& .MuiMenu-paper": {
                                    backgroundColor: "var(--bg)",
                                    borderRadius: 0,
                                },
                                "& .MuiMenu-list": {
                                    padding: 0
                                }
                            }}
                        >
                            <NavLink to={ROUTES.client.myProfile} className={`${classes.link} ${classes.big}`}>
                                <CiUser/>
                                <span>{t("menu.my_profile")}</span>
                            </NavLink>
                            <button onClick={handleLogout} className={`${classes.link} ${classes.big}`}>
                                <GrLogout/>
                                <span>{t("menu.logout")}</span>
                            </button>
                            <div className={classes.small}>
                                <NavLink to={ROUTES.client.faq} className={`${classes.link} ${classes.smallLink}`}>
                                    <span>{t("menu.help")}</span>
                                </NavLink>
                                <NavLink to={ROUTES.client.faqReport} className={`${classes.link} ${classes.smallLink}`}>
                                    <span>{t("menu.report")}</span>
                                </NavLink>
                                <NavLink to={ROUTES.client.imprint} className={`${classes.link} ${classes.smallLink}`}>
                                    <span>{t("menu.imprint")}</span>
                                </NavLink>
                                <NavLink to={ROUTES.client.privacyPolicy} className={`${classes.link} ${classes.smallLink}`}>
                                    <span>{t("menu.privacy_policy")}</span>
                                </NavLink>
                                <NavLink to={ROUTES.client.terms} className={`${classes.link} ${classes.smallLink}`}>
                                    <span>{t("menu.terms_and_conditions")}</span>
                                </NavLink>
                            </div>
                        </Menu>
                    </div>
                </div>
            </Container>
            <Container className={classes.mobileContainer}>
                <MainNavigation handleClickSearch={handleClickSearch}/>
            </Container>
        </div>
    )
}

export default connect(null, {
    logout, setIsBuySubscribeOpen
})(Navbar)