import { combineReducers, applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import multi from 'redux-multi';
import commonReducer from './commonReducer';
import userReducer from './userReducer';
import profilesReducer from './profilesReducer';
import actionsReducer from './actionsReducer';
import chatsReducer from './chatsReducer';

let reducers = combineReducers({
    common: commonReducer,
    user: userReducer,
    profiles: profilesReducer,
    actions: actionsReducer,
    chats: chatsReducer
})

let store = createStore(reducers, applyMiddleware(thunk, multi))

window.store = store

export default store