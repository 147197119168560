import classes from "./Home.module.css"
import ProfileItem from "../../../Components/Profile/ProfileItem/ProfileItem"
import InfiniteScroll from "../../../Components/Common/InfinityScroll/InfinityScroll"

const Home = (props) => {
    const { profiles, getProfiles, pageNumber, setPageNumber } = props

    return (
        <InfiniteScroll
            className={classes.main}
            fetchData={getProfiles}
            page={pageNumber}
            setPage={setPageNumber}
        >
            {profiles.map(el => (
                <ProfileItem key={el._id} item={el}/>
            ))}
        </InfiniteScroll>
    )
}

export default Home