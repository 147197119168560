import { useEffect } from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router"
import { getFavorites, getLikes, getVisitors } from "../../../Redux/actionsReducer"
import { getProfiles } from "../../../Redux/profilesReducer"
import { ROUTES } from "../../../Utils/constants"
import Action from "./Action"

const ActionContainer = (props) => {
    const {
        getVisitors,
        getLikes,
        getFavorites,
        getProfiles,
        likes,
        favorites,
        visitors,
        user,
        profiles
    } = props

    const location = useLocation()

    useEffect(() => {
        if (user) {
            if (location.pathname.includes(ROUTES.client.visitors)) {
                getVisitors()
            } else if (location.pathname.includes(ROUTES.client.favorites)) {
                getFavorites()
            } else if (location.pathname.includes(ROUTES.client.likes)) {
                getLikes()
            }
        }
    }, [location])

    useEffect(() => {
        getProfiles({
            count: 1,
            limit: 16,
            looking_for: user?.filter_settings?.looking_for || "",
            age_min: user?.filter_settings?.age_range?.min || "",
            age_max: user?.filter_settings?.age_range?.max || "",
            preferences: user?.filter_settings?.preferences.join(",") || "",
            randomize: true,
            current_user: user?.username || "",
            excluded_users: location.pathname.includes(ROUTES.client.visitors) ? visitors.map(el => el.username) : 
                location.pathname.includes(ROUTES.client.favorites) ? favorites.map(el => el.username) :
                location.pathname.includes(ROUTES.client.likes) && likes.map(el => el.username)
        })
    }, [likes, favorites, visitors])

    return (
        <Action
            usersByType={
                location.pathname.includes(ROUTES.client.visitors) ? { type: "visitors", users: visitors } :
                location.pathname.includes(ROUTES.client.favorites) ? { type: "favorites", users: favorites }  :
                location.pathname.includes(ROUTES.client.likes) && { type: "likes", users: likes } 
            }
            profiles={profiles}
        />
    )
}

let mapStateToProps = (state) => ({
    likes: state.actions.likes,
    visitors: state.actions.visitors,
    favorites: state.actions.favorites,
    user: state.user.user,
    profiles: state.profiles.profiles,
})

export default connect(mapStateToProps, {
    getVisitors,
    getLikes,
    getFavorites,
    getProfiles
})(ActionContainer)