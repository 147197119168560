import classes from "./Overflow.module.css"

const Overflow = (props) => {
    const { children, className, zIndex = 10 } = props

    return (
        <div
            className={`${classes.main} ${className}`}
            style={{ zIndex: zIndex }}
        >
            {children}
        </div>
    )
}

export default Overflow