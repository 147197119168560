import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useSocket } from "../../../Components/Common/SocketProvider"
import { getChats, getChat, setChatData, deleteChat, setChatsData } from "../../../Redux/chatsReducer"
import { getProfiles } from "../../../Redux/profilesReducer"
import Chat from "./Chat"

const ChatContainer = (props) => {
    const {
        getChats,
        chats,
        getChat,
        chat,
        setChatData,
        user,
        deleteChat,
        setChatsData,
        getProfiles,
        profiles
    } = props

    const socket = useSocket()

    const [isFirstVisit, setIsFirstVisit] = useState(true)

    const [currentChat, setCurrentChat] = useState(null)

    const handleDelete = (chatId) => {
        const newChats = chats.filter(el => el._id !== chatId)
        setChatsData(newChats)
        deleteChat(chatId).then(response => {
            if (response) {
                if (chatId === currentChat._id) {
                    setCurrentChat(chats.length > 0 ? chats[0] : null)
                    setChatData(null)
                }
            }
        })
    }

    useEffect(() => {
        if (user) {
            getChats()
            getProfiles({
                count: 1,
                limit: 16,
                looking_for: user?.filter_settings?.looking_for || "",
                age_min: user?.filter_settings?.age_range?.min || "",
                age_max: user?.filter_settings?.age_range?.max || "",
                preferences: user?.filter_settings?.preferences.join(",") || "",
                randomize: true,
                current_user: user?.username || ""
            })
        }
    }, [user])

    useEffect(() => {
        if (user && chat !== null) {
            socket.emit("joinRoom", {
                userId: user._id,
                companionId: chat.participants[0]._id
            })

            socket.on('message', (message) => {
                if (message.chat_id === chat._id) {
                    const newMessages = [ ...chat.messages ]
                    newMessages.push(message)
        
                    setChatData({ ...chat, messages: newMessages })
                }
            });
        }
    }, [user, chat])

    useEffect(() => {
        if (chats && chats.length > 0 && isFirstVisit) {
            setCurrentChat(chats[0])
            setIsFirstVisit(false)
        }
    }, [chats])


    useEffect(() => {
        if (currentChat) {
            getChat(currentChat._id)
        }
    }, [currentChat])

    return (
        <Chat
            chats={chats}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            chat={chat}
            handleDelete={handleDelete}
            profiles={profiles}
        />
    )
}

let mapStateToProps = (state) => ({
    chats: state.chats.chats,
    chat: state.chats.chat,
    user: state.user.user,
    profiles: state.profiles.profiles,
})

export default connect(mapStateToProps, {
    getChats,
    getChat,
    setChatData,
    deleteChat,
    setChatsData,
    getProfiles
})(ChatContainer)