import { useState, useEffect } from 'react';
import ChatComponent from "../../../Components/Chat/ChatComponent"
import ChatList from "../../../Components/Chat/ChatList/ChatList"
import classes from "./Chat.module.css"
import InfoMessage from "../../../Components/Common/InfoMessage/InfoMessage"
import { useTranslation } from "react-i18next"
import ProfilesWrapper from "../../../Components/Common/ProfilesWrapper/ProfilesWrapper"
import useScreenWidth from "../../../Hooks/useScreenWidth"

const Chat = (props) => {
    const {
        chats,
        currentChat,
        setCurrentChat,
        chat,
        handleDelete,
        profiles,
        id,
        messages,
        setLocalMessages
    } = props

    const { t } = useTranslation()
    const screenWidth = useScreenWidth()
    
    // Add state to control delayed rendering
    const [shouldRenderChat, setShouldRenderChat] = useState(screenWidth >= 768 || !!id);

    useEffect(() => {
        if (screenWidth < 768 && !id) {
            // Delay hiding the component to avoid ResizeObserver issues
            const timer = setTimeout(() => setShouldRenderChat(false), 100); // 100ms delay
            return () => clearTimeout(timer);
        } else {
            setShouldRenderChat(true);
        }
    }, [screenWidth, id]);

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                {(chats && chats.length > 0 && !id) && (
                    <div className={classes.list}>
                        <ChatList
                            chats={chats}
                            currentChat={currentChat}
                            setCurrentChat={setCurrentChat}
                            handleDelete={handleDelete}
                        />
                    </div>
                )}
                {/* Conditionally render the chat component based on shouldRenderChat */}
                <div className={`${classes.chat} ${shouldRenderChat ? "" : classes.hide} ${id ? classes.full : ""}`}>
                    {shouldRenderChat && chat && (
                        <ChatComponent
                            chat={chat}
                            messages={messages}
                            handleDelete={handleDelete}
                            id={id}
                            setLocalMessages={setLocalMessages}
                        />
                    )}
                </div>
            </div>
            {chats && <InfoMessage text={chats.length > 0 ? t("actionPage.maybe") : t("chat.empty")}/>}
            <ProfilesWrapper profiles={profiles}/>
        </div>
    )   
}

export default Chat;
