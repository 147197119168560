import { actionsApi } from "../Api/api"
import { setIsFetching, setServerError } from "./commonReducer"

const SET_LIKES_DATA = "SET_LIKES_DATA"
const SET_VISITORS_DATA = "SET_VISITORS_DATA"
const SET_FAVORITES_DATA = "SET_FAVORITES_DATA"
const SET_NOTIFICATIONS_DATA = "SET_NOTIFICATIONS_DATA"
const SET_CURRENT_FAVORITE_DATA = "SET_CURRENT_FAVORITE_DATA"

let initialState = {
    likes: [],
    visitors: [],
    favorites: [],
    notifications: [],
    currentFavorite: null
}

let actionsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_LIKES_DATA: {
            return { ...state, likes: action.likes }
        }
        case SET_VISITORS_DATA: {
            return { ...state, visitors: action.visitors }
        }
        case SET_NOTIFICATIONS_DATA: {
            return { ...state, notifications: action.notifications }
        }
        case SET_FAVORITES_DATA: {
            return { ...state, favorites: action.favorites }
        }
        case SET_CURRENT_FAVORITE_DATA: {
            return { ...state, currentFavorite: action.currentFavorite }
        }
        default: {
            return state
        }
    }
}

export const setLikesData = (likes) => ({
    type: SET_LIKES_DATA, likes
})
export const setVisitorsData = (visitors) => ({
    type: SET_VISITORS_DATA, visitors
})
export const setNotificationsData = (notifications) => ({
    type: SET_NOTIFICATIONS_DATA, notifications
})
export const setFavoritesData = (favorites) => ({
    type: SET_FAVORITES_DATA, favorites
})
export const setCurrentFavoriteData = (currentFavorite) => ({
    type: SET_CURRENT_FAVORITE_DATA, currentFavorite
})

export const getLikes = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await actionsApi.getLikes()
        dispatch(setLikesData(response.users))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const getVisitors = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await actionsApi.getVisitors()
        dispatch(setVisitorsData(response.users))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const getFavorites = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await actionsApi.getFavorites()
        dispatch(setFavoritesData(response.users))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const addToFavorites = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await actionsApi.addToFavorites(data)
        return true
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
        return false
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const getNotifications = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await actionsApi.getNotifications()
        dispatch(setNotificationsData(response.notifications))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const readNotification = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        await actionsApi.readNotification(id)
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export default actionsReducer