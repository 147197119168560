import axios from "axios"

// const baseURL = "http://localhost:3002/api"
const baseURL = "https://api.intimeet.me/api"

const instance = axios.create({
    baseURL: baseURL
})

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        // If 401 error and it's not a retry
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh the token using the refresh token
                const refreshToken = localStorage.getItem('refreshToken');
                const { data } = await axios.post(`${baseURL}/auth/refresh`, { refreshToken });

                // Store the new access token
                localStorage.setItem('accessToken', data.token);

                // Update the Authorization header with the new token
                axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
                originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

                // Retry the original request with the new token
                return axios(originalRequest);
            } catch (err) {
                console.error('Token refresh failed:', err);
                // Handle token refresh failure (e.g., log out the user or redirect to login)
                return Promise.reject(err);
            }
        }

        return Promise.reject(error);
    }
);

export const authApi = {
    login(data) {
        return instance
            .post(`/auth/login`, data)
            .then((response) => response.data)
    },
    register(data) {
        return instance
            .post(`/auth`, data)
            .then(response => response.data)
    },
    me() {
        return instance.get("/auth/me").then((response) => response.data)
    }
}

export const profilesApi = {
    getProfiles(data) {
        const params = new URLSearchParams({ ...data });
    
        const queryString = params.toString();

        return instance
            .get(`/user?${queryString}`)
            .then(response => response.data)
    },
    getProfile(username) {
        return instance
            .get(`/user/${username}`)
            .then(response => response.data)
    },
    updateProfile(username, data) {
        return instance
            .patch(`/user/${username}`, data, data.photos && {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => response.data)
    }
}

export const actionsApi = {
    getLikes() {
        return instance
            .get("/action/likes")
            .then(response => response.data)
    },
    getVisitors() {
        return instance
            .get("/action/visitors")
            .then(response => response.data)
    },
    getFavorites() {
        return instance
            .get("/action/favorites")
            .then(response => response.data)
    },
    addToFavorites(data) {
        return instance
            .post("/action/favorites", data)
            .then(response => response.data)
    },
    getNotifications() {
        return instance
            .get("/action/notifications")
            .then(response => response.data)
    },
    readNotification(id) {
        return instance
            .delete(`/action/notifications/${id}`)
            .then(response => response.data)
    }
}

export const chatApi = {
    getChats() {
        return instance
            .get("/chat")
            .then(response => response.data)
    },
    getChat(chatId) {
        return instance
            .get(`/chat/${chatId}`)
            .then(response => response.data)
    },
    createChat(data) {
        return instance
            .post("/chat/createChat", data)
            .then(response => response.data)
    },
    uploadPhoto(data) {
        return instance
            .post(`/chat/uploadPhoto`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => response.data)
    },
    deleteChat(chatId) {
        return instance
            .delete(`/chat/${chatId}`)
            .then(response => response.data)
    }
}