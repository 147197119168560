import classes from "./InfoMessage.module.css"

const InfoMessage = (props) => {
    const { text } = props

    return (
        <div className={classes.message}>
            {text}
        </div>
    )
}

export default InfoMessage