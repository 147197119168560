import classes from "./Action.module.css"
import { useTranslation } from "react-i18next"
import ProfileItem from "../../../Components/Profile/ProfileItem/ProfileItem"
import InfoMessage from "../../../Components/Common/InfoMessage/InfoMessage"
import ProfilesWrapper from "../../../Components/Common/ProfilesWrapper/ProfilesWrapper"

const Action = (props) => {
    const { usersByType, profiles } = props

    const users = usersByType.users.filter((user, index, self) => 
        index === self.findIndex((u) => u._id === user._id)
    );

    const { t } = useTranslation()

    return (
        <div className={classes.main}>
            {users.length > 0 && (
                <ProfilesWrapper profiles={users}/>
            )}
            <InfoMessage
                text={users.length === 0 ? t(`actionPage.empty.${usersByType.type}`) : t(`actionPage.maybe`)}
            />
            <ProfilesWrapper profiles={profiles}/>
        </div>
    )
}

export default Action