import { chatApi } from "../Api/api"
import { setIsFetching, setServerError } from "./commonReducer"

const SET_CHATS_DATA = "SET_CHATS_DATA"
const SET_CHAT_DATA = "SET_CHAT_DATA"

let initialState = {
    chats: [],
    chat: null
}

let chatsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CHATS_DATA: {
            return { ...state, chats: action.chats }
        }
        case SET_CHAT_DATA: {
            return { ...state, chat: action.chat }
        }
        default: {
            return state
        }
    }
}

export const setChatsData = (chats) => ({
    type: SET_CHATS_DATA, chats
})
export const setChatData = (chat) => ({
    type: SET_CHAT_DATA, chat
})

export const getChats = () => async (dispatch) => {
    try {
        dispatch(setIsFetching(true))
        let response = await chatApi.getChats()
        dispatch(setChatsData(response))
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const getChat = (chatId) => async (dispatch) => {
    try {
        dispatch(setIsFetching(true))
        let response = await chatApi.getChat(chatId)
        dispatch(setChatData(response.chat))
        return true
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
        return false
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const createChat = (data) => async (dispatch) => {
    try {
        dispatch(setIsFetching(true))
        let response = await chatApi.createChat(data)
        return response.chatId
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
        return null
    } finally {
        dispatch(setIsFetching(false))
    }
}

export const deleteChat = (chatId) => async (dispatch) => {
    try {
        dispatch(setIsFetching(true))
        await chatApi.deleteChat(chatId)
        return true
    } catch (err) {
        dispatch(setServerError(err.response.data.message))
        return false
    } finally {
        dispatch(setIsFetching(false)) 
    }
}

export default chatsReducer