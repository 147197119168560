import { NavLink } from "react-router-dom"
import { ROUTES } from "../../../Utils/constants"
import OnlineTag from "../OnlineTag/OnlineTag"
import classes from "./ProfileItem.module.css"
import { useNavigate } from "react-router"
import { IoIosChatboxes } from "react-icons/io";
import { AiFillHeart } from "react-icons/ai";
import { useEffect, useState } from "react"
import { useSocket } from "../../Common/SocketProvider"
import { connect } from "react-redux"
import { createChat } from "../../../Redux/chatsReducer"

const ProfileItem = (props) => {
    const { item, user, createChat } = props

    const socket = useSocket()

    const navigate = useNavigate()

    const [isLiked, setIsLiked] = useState(false)

    const likeUnlike = () => {
        setIsLiked(prev => !prev);
        socket.emit("likeUnlike", {
            userId: user._id,
            liked_user_id: item._id
        })

        socket.once("likeUnlikeResponse", (response) => {
            if (!response.success) {
                setIsLiked(prev => !prev);
            }
        });
    }

    const handleCreateChat = () => {
        createChat({
            userId: user._id,
            companionId: item._id
        }).then(chatId => {
            if (chatId) {
                navigate(`${ROUTES.client.chats}/${chatId}`)
            }
        })
    }

    useEffect(() => {
        if (item.is_liked) {
            setIsLiked(true)
        }
    }, [item])

    return (
        <div className={classes.main}>
            <img onClick={() => navigate(`${ROUTES.client.profile}/${item.username}`)} src={item.photos[0]} alt={item.username}/>
            <div className={classes.footer}>
                <NavLink to={`${ROUTES.client.profile}/${item.username}`} className={classes.info}>
                    <div className={classes.userInfo}>
                        {item.username},&nbsp;
                        {item.age}&nbsp;
                        <OnlineTag isOnline={item.is_online} size="small"/>
                    </div>
                </NavLink>
                <div className={classes.footFoot}>
                    <NavLink to={`${ROUTES.client.profile}/${item.username}`} className={classes.info}>
                        <p>&lt; {item.distance}km</p>
                    </NavLink>
                    <div className={classes.actions}>
                        <button onClick={handleCreateChat}>
                            <IoIosChatboxes/>
                        </button>
                        <button
                            onClick={likeUnlike}
                            className={isLiked ? classes.liked : ""}
                        >
                            <AiFillHeart/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

let mapStateToProps = (state) => ({
    user: state.user.user
})

export default connect(mapStateToProps, {
    createChat
})(ProfileItem)