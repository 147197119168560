import classes from "./OnlineTag.module.css"

const OnlineTag = (props) => {
    const { isOnline, size } = props

    return (
        <div
            className={`${classes.main} ${classes[size]}`}
            style={{
                backgroundColor: isOnline ? "#16be36" : "#f30800"
            }}
        />
    )
}

export default OnlineTag