import { useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import AuthClientLayout from "../../../Components/Auth/Client/AuthClientLayout/AuthClientLayout"
import AuthModal from "../../../Components/Auth/Client/AuthModal/AuthModal"
import CustomButton from "../../../Components/UI/CustomButton/CustomButton"
import Input from "../../../Components/UI/Form/Input"
import Header from "../../../Components/UI/Text/Header/Header"
import { setServerError } from "../../../Redux/commonReducer"
import classes from "./Auth.module.css"

const ForgotPassword = (props) => {
    const { setServerError } = props

    const { t } = useTranslation()

    const { handleSubmit, control } = useForm()

    const onSubmit = (data) => {
        setServerError(null)
        console.log(data)
    }

    return (
        <AuthClientLayout>
            <AuthModal>
                <div className={classes.content}>
                    <Header type="h3" text={t("auth.forgotPass.text")}/>
                    <p><strong>{t("auth.forgotPass.text2")}</strong></p>
                    <p>{t("auth.forgotPass.text3")}</p>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("errors.required") }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    placeholder={t("auth.email")}
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                />
                            )}
                        />
                        <CustomButton type="submit" text={t("auth.forgotPass.submit")}/>
                    </form>
                </div>
            </AuthModal>
        </AuthClientLayout>
    )
}

let mapStateToProps = (state) => ({
    serverError: state.common.serverError,
    isAuth: state.user.isAuth
})

export default connect(mapStateToProps, {

})(ForgotPassword)