import { useTranslation } from "react-i18next"
import classes from "./Filter.module.css"
import { RiArrowDropUpLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import CustomButton from "../../../CustomButton/CustomButton";
import { PREFERENCES, ROUTES } from "../../../../../Utils/constants";
import { useNavigate } from "react-router";

const ageRanges = [
    { min: 18, max: 30 },
    { min: 31, max: 55 },
    { min: 56, max: 99 },
];

const Filter = (props) => {
    const { user, getProfiles, setIsBuySubscribeOpen } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(true)

    const [gender, setGender] = useState(user.filter_settings.looking_for || "woman")
    const [types, setTypes] = useState(user.filter_settings.types || [])
    const [selectedAgeRanges, setSelectedAgeRanges] = useState([ageRanges[0]]);
    const [selectedPreferences, setSelectedPreferences] = useState(user.filter_settings.preferences)

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleGender = (gender) => {
        setGender(gender)
    }

    const handleSelectType = (type) => {
        if (type === "naughty" && !user?.is_subscribed) {
            setIsBuySubscribeOpen(true)
        } else {
            const selectedType = types.includes(type)
            if (selectedType) {
                setTypes(types.filter(el => el !== type))
            } else {
                setTypes([...types, type])
            }
        }
    }

    const toggleRange = (range) => {
        setSelectedAgeRanges((prevSelectedRanges) => {
            const isSelected = prevSelectedRanges.some(
                (r) => r.min === range.min && r.max === range.max
            );
            if (isSelected) {
                return prevSelectedRanges.filter(
                    (r) => r.min !== range.min || r.max !== range.max
                );
            } else {
                return [...prevSelectedRanges, range];
            }
        });
    };
    
      const getMinMax = () => {
        if (selectedAgeRanges.length === 0) {
            return { min: null, max: null };
        }
        const allMins = selectedAgeRanges.map((range) => range.min);
        const allMaxs = selectedAgeRanges.map((range) => range.max);
        return { min: Math.min(...allMins), max: Math.max(...allMaxs) };
    };

    const handleSelectTag = (tag) => {
        let newSelectedPreferences = [...selectedPreferences]
        if (selectedPreferences.includes(tag)) {
            newSelectedPreferences = newSelectedPreferences.filter(el => el !== tag)
        } else {
            newSelectedPreferences.push(tag)
        }
        setSelectedPreferences(newSelectedPreferences)
    }

    const search = () => {
        getProfiles({
            looking_for: gender,
            age_min: getMinMax().min,
            age_max: getMinMax().max,
            preferences: selectedPreferences,
            randomize: true,
            current_user: user.username,
            types: types
        })
        navigate(ROUTES.client.home)
    }

    useEffect(() => {
        getProfiles({
            looking_for: gender,
            age_min: getMinMax().min,
            age_max: getMinMax().max,
            preferences: selectedPreferences,
            randomize: true,
            current_user: user.username,
            types: types
        })
    }, [gender, selectedAgeRanges, selectedPreferences, types])

    return (
        <div className={classes.main}>
            <div
                className={`${classes.title} ${isOpen ? classes.open : ""}`}
                onClick={handleOpen}
            >
                <p>{t("sidebar.search")}</p>
                <RiArrowDropUpLine/>
            </div>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <div className={classes.sections}>
                    <div className={classes.section}>
                        <p className={classes.sectionTitle}>{t("sidebar.looking_for")}</p>
                        <div className={classes.buttons}>
                            <CustomButton
                                text={t("sidebar.woman")}
                                onClick={() => {
                                    handleGender("woman")
                                    navigate(ROUTES.client.home)
                                }}
                                className={gender === "woman" ? "" : classes.activeButton}
                            />
                            <CustomButton
                                text={t("sidebar.man")}
                                onClick={() => {
                                    handleGender("man")
                                    navigate(ROUTES.client.home)
                                }}
                                className={gender === "man" ? "" : classes.activeButton}
                            />
                        </div>
                    </div>
                    <div className={classes.section}>
                        <p className={classes.sectionTitle}>{t("sidebar.photoType")}</p>
                        <div className={classes.buttons}>
                            <CustomButton
                                text={t("sidebar.types.modest")}
                                onClick={() => {
                                    handleSelectType("modest")
                                    navigate(ROUTES.client.home)
                                }}
                                className={types.includes("modest") ? "" : classes.activeButton}
                            />
                            <CustomButton
                                text={t("sidebar.types.flirty")}
                                onClick={() => {
                                    handleSelectType("flirty")
                                    navigate(ROUTES.client.home)
                                }}
                                className={types.includes("flirty") ? "" : classes.activeButton}
                            />
                        </div>
                        {/* <CustomButton
                            text={`🌶️ ${t("sidebar.types.naughty")}`}
                            onClick={() => {
                                handleSelectType("naughty")
                                navigate(ROUTES.client.home)
                            }}
                            className={`${types.includes("naughty") ? "" : classes.activeButton} ${classes.fireButton}`}
                        /> */}
                    </div>
                    <div className={classes.section}>
                        <p className={classes.sectionTitle}>{t("sidebar.age_range")}</p>
                        <div className={classes.buttons}>
                            {ageRanges.map(el => (
                                <CustomButton
                                    key={`${el.min}-${el.max}`}
                                    text={`${el.min} - ${el.max}`}
                                    onClick={() => {
                                        toggleRange(el)
                                        navigate(ROUTES.client.home)
                                    }}
                                    className={`${selectedAgeRanges.some(
                                        (r) => r.min === el.min && r.max === el.max
                                    ) ? "" : classes.activeButton
                                    } ${classes.ageButton}`}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={classes.section}>
                        <p className={classes.sectionTitle}>{t("sidebar.preferences")}</p>
                        <div className={`${classes.buttons} ${classes.preferencesButtons}`}>
                            {PREFERENCES.map(el => (
                                <CustomButton
                                    key={el}
                                    text={t(`sidebar.preferences_items.${el}`)}
                                    className={selectedPreferences.some(pr => pr === el) ? "" : classes.activeButton}
                                    onClick={() => {
                                        handleSelectTag(el)
                                        navigate(ROUTES.client.home)
                                    }}
                                />
                            ))}
                        </div>
                        <div className={classes.preferencesControls}>
                            <button className={classes.controlButton} onClick={() => {
                                setSelectedPreferences(PREFERENCES)
                                navigate(ROUTES.client.home)
                            }}
                            >{t("sidebar.select_all")}</button>
                            <button className={classes.controlButton} onClick={() => {
                                setSelectedPreferences([])
                                navigate(ROUTES.client.home)
                            }}>{t("sidebar.none")}</button>
                        </div>
                    </div>
                    <CustomButton text={t("sidebar.search")} onClick={search}/>
                </div>
            </Collapse>
        </div>
    )
}

export default Filter