import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getLikes, getVisitors, readNotification, setNotificationsData } from "../../../Redux/actionsReducer"
import { getChats } from "../../../Redux/chatsReducer"
import { setIsBuySubscribeOpen } from "../../../Redux/commonReducer"
import { getProfiles } from "../../../Redux/profilesReducer"
import Footer from "../../Common/Footer/Footer"
import Navbar from "../../Common/Navbar/Navbar"
import Preloader from "../../Common/Preloader/Preloader"
import Container from "../Container/Container"
import classes from "./Layout.module.css"
import MainNavigation from "./MainNavgation/MainNavigation"
import Filter from "./Sidebar/Filter/Filter"
import Sidebar from "./Sidebar/Sidebar"
import NotificationItem from "../../Common/NotificationItem/NotificationItem";
import { ROUTES } from "../../../Utils/constants"
import { useLocation } from "react-router"

const Layout = (props) => {
    const {
        user,
        visitors,
        likes,
        isFetching,
        getLikes,
        getVisitors,
        getChats,
        getProfiles,
        setIsBuySubscribeOpen,
        readNotification,
        setNotificationsData,
        notifications,
        children,
    } = props

    const [isOpenSearchMobile, setIsOpenSearchMobile] = useState(false)

    const location = useLocation()

    const onRemoveNotification = (id) => {
        const newNotifications = notifications.filter(el => el._id !== id)
        readNotification(id)
        setNotificationsData(newNotifications)
    }

    useEffect(() => {
        getChats()
        getLikes()
        getVisitors()
    }, [])

    return (
        <div className={classes.main}>
            {isFetching && <Preloader/>}
            <Navbar user={user} setIsOpenSearchMobile={setIsOpenSearchMobile}/>
            <Container className={`${classes.container} ${location.pathname.includes(ROUTES.client.chats) ? classes.chat : ""}`}>
                <div className={classes.sidebar}>
                    <Sidebar user={user}/>
                </div>
                {!location.pathname.includes(ROUTES.client.chats) && (
                    <div className={classes.mobileNotifications}>
                        {notifications.map(el => (
                            <NotificationItem
                                key={el._id}
                                item={el}
                                onRemoveNotification={onRemoveNotification}
                            />
                        ))}
                    </div>
                )}
                <div className={classes.content}>
                    <div className={classes.mainNavigation}>
                        <MainNavigation visitors={visitors} likes={likes}/>
                    </div>
                    {isOpenSearchMobile && (
                        <Filter
                            user={user}
                            getProfiles={getProfiles}
                            setIsBuySubscribeOpen={setIsBuySubscribeOpen}
                        />
                    )}
                    {children}
                </div>
            </Container>
            <Footer/>
        </div>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    user: state.user.user,
    likes: state.actions.likes,
    visitors: state.actions.visitors,
    notifications: state.actions.notifications
})

export default connect(mapStateToProps, {
    getLikes,
    getVisitors,
    getChats,
    getProfiles,
    setIsBuySubscribeOpen,
    readNotification,
    setNotificationsData
})(Layout)