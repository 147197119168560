import Overflow from "../Overflow/Overflow"
import classes from "./CtaModal.module.css"
import { IoMdClose } from "react-icons/io";

const CtaModal = (props) => {
    const { onClose, children } = props

    return (
        <Overflow>
            <div className={classes.main}>
                <button onClick={onClose} className={classes.closeBut}>
                    <IoMdClose/>
                </button>
                {children}
            </div>
        </Overflow>
    )
}

export default CtaModal