import OnlineTag from "../../Profile/OnlineTag/OnlineTag"
import classes from "./ChatHeader.module.css"
import { AiFillHeart } from "react-icons/ai";
import { BsFillStarFill } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import { useSocket } from "../../Common/SocketProvider";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addToFavorites } from "../../../Redux/actionsReducer";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../Utils/constants";
import ChatPhotoSliderModal from "../ChatPhotoSliderModal/ChatPhotoSliderModal";
import { getDefaultUserPhoto } from "../../../Utils/getDefaultUserPhoto";
import { IoChevronBackSharp } from "react-icons/io5";

const ChatHeader = (props) => {
    const { chat, user, addToFavorites, handleDelete, id } = props

    const navigate = useNavigate()
    const socket = useSocket()

    const [isLiked, setIsLiked] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)

    const [isOpenPhotosModal, setIsOpenPhotosModal] = useState(false)

    const companion = chat.participants[0]

    const navigateToUserPage = () => {
        navigate(`${ROUTES.client.profile}/${companion.username}`)
    }

    const handlePhotosOpen = () => {
        setIsOpenPhotosModal(!isOpenPhotosModal)
    }

    const likeUnlike = () => {
        setIsLiked(prev => !prev);
        socket.emit("likeUnlike", {
            userId: user._id,
            liked_user_id: companion._id
        })

        socket.once("likeUnlikeResponse", (response) => {
            if (!response.success) {
                setIsLiked(prev => !prev);
            }
        });
    }

    const favoriteUnfavorite = () => {
        setIsFavorite(!isFavorite)
        addToFavorites({ user_id: user._id, favorite_user_id: companion._id }).then(response => {
            if (!response) {
                setIsFavorite(!isFavorite)
            }
        })
    }

    useEffect(() => {
        if (companion) {
            if (companion.is_liked) {
                setIsLiked(true)
            }
            if (companion.is_favorite) {
                setIsFavorite(true)
            }
        }
    }, [companion])

    return (
        <div className={classes.main}>
            {isOpenPhotosModal && (
                <ChatPhotoSliderModal
                    photos={companion.photos}
                    onClose={handlePhotosOpen}
                />
            )}
            <div className={classes.user}>
                {id && (
                    <button
                        className={classes.backButt}
                        onClick={() => navigate(ROUTES.client.chats)}
                    >
                        <IoChevronBackSharp/>
                    </button>
                )}
                <div className={classes.photo} onClick={handlePhotosOpen}>
                    <img src={companion.photos.length > 0 ? companion.photos[0] : getDefaultUserPhoto(companion.gender)} alt={companion.username}/>
                    <div className={classes.online}>
                        <OnlineTag isOnline={companion.is_online} size={"big"}/>
                    </div>
                </div>
                <div className={classes.userInfo}>
                    <p className={classes.name} onClick={navigateToUserPage}>{companion.username}, {companion.age}</p>
                    <p>&lt; {companion.distance}km</p>
                </div>
            </div>
            <div className={classes.actions}>
                <div className={classes.likeFavorite}>
                    <button
                        className={isLiked ? classes.active : ""}
                        onClick={likeUnlike}
                    >
                        <AiFillHeart/>
                    </button>
                    <button
                        className={isFavorite ? classes.active : ""}
                        onClick={favoriteUnfavorite}
                    >
                        <BsFillStarFill/>
                    </button>
                </div>
                <button onClick={() => handleDelete(chat._id)}>
                    <FaRegTrashAlt/>
                </button>
            </div>
        </div>
    )
}

let mapStateToProps = (state) => ({
    user: state.user.user
})

export default connect(mapStateToProps, {
    addToFavorites
})(ChatHeader)